import type React from "react";

interface LoaderProps {
    loaderSize?: "large" | "block";
    repeat?: number;
    className?: string;
}

const Loader: React.FC<LoaderProps> = ({ loaderSize, repeat, className }) => {
    const returnLoaderSize = loaderSize ? loaderSize : "";

    const renderLoaders = () => {
        const items = [];
        if (repeat) {
            for (let i = 0; i < repeat; i++) {
                items.push(
                    <div
                        key={`loader-${i}`}
                        className={`loader ${returnLoaderSize}`}
                    />
                );
            }
            return items;
        } else return <div className={`loader ${returnLoaderSize}`} />;
    };

    return <div className={className}>{renderLoaders()}</div>;
};

export default Loader;
