// global css imports
import "flexboxgrid";
import "./scss/index.scss";

// js imports
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "@js/App";
import { store } from "@js/store/store";

// import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(
        <Provider store={store}>
            <App />
        </Provider>
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
