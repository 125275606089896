import type React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import HeroErrorBoundary from "@js/organisms/HeroErrorBoundary";
import Header from "@js/organisms/Header";
import Footer from "@js/organisms/Footer";
import { Button } from "@js/atoms/Button";

interface Props {
    heroMessage?: string;
}

const ErrorBoundary: React.FC<Props> = ({ heroMessage }) => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate("/");
    };

    const handleFaqClick = () => {
        navigate("/faq");
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children's Guardian | Self Assessment | Error
                    Not Found
                </title>
            </Helmet>
            <div className="landing">
                <Header />
                <HeroErrorBoundary message={heroMessage} />
                <div className="landing__body-error">
                    <div className="landing__body__inner">
                        <p className="mt-3">
                            The content you are trying to access can't be found.
                            It may no longer be published at that link. Instead,
                            you can return to the Child Safe Self-Assessment
                            homepage or visit our FAQs which may be related to
                            your search.
                        </p>
                        <Button
                            anchorText="Go to Homepage"
                            outlineButton={false}
                            onClick={() => handleHomeClick()}
                            className="mb-5 mr-1 mt-1"
                        />
                        <Button
                            anchorText="See FAQ's"
                            outlineButton={true}
                            onClick={() => handleFaqClick()}
                            className="mb-5 mt-1"
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ErrorBoundary;
