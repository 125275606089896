import type React from "react";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import img from "@assets/img/illustration-questionnaire.svg";
import {
    FormIntro,
    ReturnMarkup,
    Tooltip,
} from "@js/molecules/StoryBlokComponents";
import {
    returnInput,
    TextInput,
    RadioInput,
    CheckboxInput,
    SelectInput,
} from "@js/molecules/StoryBlokFormInputComponents";
import { Button } from "@js/atoms/Button";
import { useForm } from "@js/slices/sliceForm";
import { useModal } from "@js/slices/sliceModal";
import { useParams, useNavigate } from "react-router-dom";
import { scrollTo, scrollIntoView } from "scroll-js";
import { useStoryBlok } from "@js/slices/sliceStoryBlok";
import { emailRegex, validatePhone } from "@js/utilities";

const Form: React.FC = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children&apos;s Guardian | Self Assessment |
                    Questionnaire
                </title>
            </Helmet>
            <div className="sat">
                <div className="sat__inner">
                    <FormSection />
                </div>
            </div>
        </>
    );
};

export default Form;

const FormSection: React.FC = () => {
    const { jsonData } = useStoryBlok();
    const {
        createEntry,
        entryId,
        section,
        completedSections,
        formData,
        apiError,
        setSection,
        setCompletedSections,
        submitForm,
        resumeInviteForm,
        isSubmittingForm,
        setIsSubmittingForm,
    } = useForm();
    const { setShowModal } = useModal();
    const navigate = useNavigate();
    const params = useParams();
    const [dependentId, setDependentId] = useState<string>("");
    const [dependentVal, setDependentVal] = useState<string>("");
    const [showDependent, setShowDependent] = useState<boolean>(false);
    const [submitErrors, setSubmitErrors] = useState<any>([]);
    const [checkErrors, setcheckErrors] = useState<boolean>(false);
    const formStepUrlParameter = params.sectionNumber
        ? Number(params.sectionNumber)
        : 0;
    const sectionData = jsonData?.story?.content.sections[formStepUrlParameter];
    const totalSections = jsonData?.story?.content.sections.length;
    const { name, intro, toolip, questions } = sectionData;
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    useEffect(() => {
        if (formStepUrlParameter !== section) {
            if (!completedSections.includes(formStepUrlParameter)) {
                navigate(`/self-assessment`);
                setSection(0);
            } else {
                setSection(formStepUrlParameter); // sync redux store with url params if user navigates via address bar
            }
            setSection(formStepUrlParameter);
        }
        questions.map((question: FormQuestion) => {
            const { dependent } = question;
            if (dependent) {
                const dependentId = dependent.split("|")[0];
                const dependentVal = dependent.split("|")[1];
                setDependentId(dependentId);
                setDependentVal(dependentVal);
            }
        });
    }, [questions]);

    useEffect(() => {
        const showDependentQuestion = formData.find(
            question => question.questionId === dependentId
        );
        if (
            showDependentQuestion &&
            showDependentQuestion.value === dependentVal
        ) {
            setShowDependent(true);
        } else {
            setShowDependent(false);
        }
    }, [formData]);

    useEffect(() => {
        resumeInviteForm();
        scrollTo(window, { top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        const contentElements = document
            .getElementById("sat-form-container")
            ?.querySelectorAll("label, b, p");

        if (contentElements) {
            for (let i = 0; i < contentElements.length; i++) {
                contentElements[i].setAttribute("tabindex", "0");
            }
        }
    }, [sectionData]);

    const validateForm = () => {
        let valid = { valid: true, elementId: "" };

        const standardValidation = () => {
            // this will contain all question ID's from the StoryBlok json for a particular section
            const questionIds: FormQuestionsValidation[] = [];
            questions.map((question: FormQuestion) => {
                questionIds.push({
                    questionId: question._uid,
                    dependentId: question.dependent,
                    answer: question.answer,
                });
            });
            // check if all sectionData question are in redux formData if they are, the form is valid
            questionIds.map(question => {
                const el = document.getElementById(question.questionId);
                const idFoundInFormData = formData.filter(
                    reduxFormDataQuestion =>
                        reduxFormDataQuestion.questionId === question.questionId
                );
                const answerType: string = question.answer[0].type;
                //check all inputs have value and exist on page (check el is not null for step 2 question 7)
                if (idFoundInFormData.length === 0 && el !== null) {
                    if (el?.tagName === "SELECT") {
                        setSubmitErrors((prevState: any) => [
                            ...prevState,
                            {
                                inputId: question.questionId,
                                error: "Please select a value",
                            },
                        ]);
                    } else {
                        setSubmitErrors((prevState: any) => [
                            ...prevState,
                            {
                                inputId: question.questionId,
                                error: "Please answer this question",
                            },
                        ]);
                    }
                    valid = {
                        valid: false,
                        elementId: question.questionId,
                    };
                }
                if (answerType === "checkbox" || answerType === "radio") {
                    question.answer[0].options.map((answer: any) => {
                        if (answer.behaviour === "free-text-required") {
                            const freeText = document.getElementById(
                                answer._uid + "|textarea"
                            ) as HTMLTextAreaElement;
                            if (freeText && freeText.value === "") {
                                setSubmitErrors((prevState: any) => [
                                    ...prevState,
                                    {
                                        inputId: question.questionId,
                                        error: "Please specify your response below",
                                    },
                                ]);
                                valid = {
                                    valid: false,
                                    elementId: question.questionId,
                                };
                            }
                        }
                    });
                }
                return valid;
            });
        };
        const nonStandardValidation = () => {
            // validation for step 1
            const radioContainer = document.getElementById(
                "radio-input-non-standard"
            );
            const checkboxContainer = document.getElementById(
                "checkbox-input-non-standard"
            );
            const elementContainer = document.getElementById(
                "sat-form-non-standard"
            );
            const inputError = document.getElementById("on-change-valid");
            const selectInput = document.getElementById(
                "state-q"
            ) as HTMLInputElement;
            const inputs = elementContainer?.querySelectorAll("input");
            const disclaimer = checkboxContainer?.querySelectorAll("input");
            const errorsExist = submitErrors.length > 0;
            let radioSelected = false;

            const updateErrors = (inputId: string, errorMessage: string) => {
                return submitErrors.map((error: any) => {
                    if (error.inputId === inputId) {
                        return {
                            ...error,
                            error: errorMessage,
                        };
                    } else return { ...error };
                });
            };

            if (inputs) {
                for (let i = 0; i < inputs.length; i++) {
                    const inputRequired = inputs[i].hasAttribute("required");
                    const inputValue = inputs[i].value;
                    const inputType = inputs[i].type;
                    const inputId = inputs[i].id;
                    // text input validation
                    if (
                        inputRequired &&
                        inputType === "text" &&
                        inputValue === ""
                    ) {
                        if (errorsExist && !checkErrors) {
                            const newState = updateErrors(
                                inputId,
                                "Please enter a value"
                            );
                            setSubmitErrors(newState);
                        } else {
                            setSubmitErrors((prevState: any) => [
                                ...prevState,
                                {
                                    inputId,
                                    error: "Please enter a value",
                                },
                            ]);
                        }
                        valid = {
                            valid: false,
                            elementId: inputId,
                        };
                    }
                    // email input validation
                    if (inputRequired && inputType === "email") {
                        if (!emailRegex.test(inputValue) && inputValue !== "") {
                            if (errorsExist && !checkErrors) {
                                const newState = updateErrors(
                                    inputId,
                                    "Please enter a valid email address"
                                );
                                setSubmitErrors(newState);
                            } else {
                                setSubmitErrors((prevState: any) => [
                                    ...prevState,
                                    {
                                        inputId,
                                        error: "Please enter a valid email address",
                                    },
                                ]);
                            }
                            valid = {
                                valid: false,
                                elementId: inputId,
                            };
                        } else if (inputValue === "") {
                            if (errorsExist && !checkErrors) {
                                const newState = updateErrors(
                                    inputId,
                                    "Please enter a value"
                                );
                                setSubmitErrors(newState);
                            } else {
                                setSubmitErrors((prevState: any) => [
                                    ...prevState,
                                    {
                                        inputId,
                                        error: "Please enter a value",
                                    },
                                ]);
                            }
                            valid = {
                                valid: false,
                                elementId: inputId,
                            };
                        }
                    }
                    // phone input validation
                    if (
                        inputType === "tel" &&
                        inputValue !== "" &&
                        !validatePhone(inputValue)
                    ) {
                        setSubmitErrors((prevState: any) => [
                            ...prevState,
                            {
                                inputId,
                                error: "Phone number must be between 8 and 12 characters",
                            },
                        ]);
                        valid = {
                            valid: false,
                            elementId: inputId,
                        };
                    }
                    if (
                        selectInput &&
                        selectInput?.value === "" &&
                        selectInput?.id === "state-q"
                    ) {
                        setSubmitErrors((prevState: any) => [
                            ...prevState,
                            {
                                inputId: "state-q",
                                error: "Select your state",
                            },
                        ]);
                        valid = {
                            valid: false,
                            elementId: "state-q",
                        };
                    }
                    if (
                        inputRequired &&
                        inputType === "radio" &&
                        inputs[i].checked
                    ) {
                        radioSelected = true;
                    }
                }
            }
            if (radioContainer && radioSelected === false) {
                setSubmitErrors((prevState: any) => [
                    ...prevState,
                    {
                        inputId: "role-a",
                        error: "Please select a role",
                    },
                ]);
                valid = {
                    valid: false,
                    elementId: "role-q",
                };
            }
            if (disclaimer) {
                for (let i = 0; i < disclaimer.length; i++) {
                    if (!disclaimer[i].checked) {
                        setSubmitErrors((prevState: any) => [
                            ...prevState,
                            {
                                inputId: "consent-q",
                                error: "Please confirm",
                            },
                        ]);
                        valid = {
                            valid: false,
                            elementId: "consent-q",
                        };
                    }
                }
            }
            if (inputError) {
                valid = {
                    valid: false,
                    elementId: "on-change-valid",
                };
            }
        };

        switch (section) {
            case 0:
                nonStandardValidation();
                break;
            case 1:
                nonStandardValidation();
                standardValidation();
                break;
            default:
                standardValidation();
        }

        return valid;
        // return { valid: true, elementId: "" };
    };

    useEffect(() => {
        if (apiError?.name === "Error") {
            if (section !== 13) {
                navigate(-1);
            }
        }
    }, [apiError]);

    const handleNext = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const valid = validateForm();
        const satForm = document.getElementById("sat-intro-step");
        if (valid.valid) {
            if (section === 0 && valid.valid === true && !entryId) {
                setIsSubmittingForm(true);
                const createEntryResponse = await createEntry(recaptchaRef);

                setIsSubmittingForm(false);

                if (createEntryResponse.error != null) {
                    // report error somehow
                    return;
                }
            }

            const nextPage = section + 1;
            setCompletedSections(section);
            setSection(section + 1);
            setcheckErrors(true);
            navigate(`/self-assessment/${nextPage}`);
            scrollTo(window, { top: 0, behavior: "smooth" });
            satForm?.focus();
        } else {
            const elementWithError = document.getElementById(valid.elementId)
                ?.parentElement?.parentElement;
            if (elementWithError !== undefined && elementWithError !== null) {
                scrollIntoView(elementWithError, document.body, {
                    behavior: "smooth",
                });
            }
        }
    };
    const handleBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const prevPage = section - 1;
        const satForm = document.getElementById("sat-intro-step");
        setSection(section - 1);
        setcheckErrors(false);
        navigate(`/self-assessment/${prevPage}`);
        scrollTo(window, { top: 0, behavior: "smooth" });
        satForm?.focus();
    };

    const handleSubmit = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        const valid = validateForm();
        if (valid.valid) {
            setIsSubmittingForm(true);
            await submitForm(recaptchaRef).then(res => {
                if (res.name === "AxiosError") {
                    setIsSubmittingForm(false);
                } else {
                    setIsSubmittingForm(false);
                    navigate(`/thankyou`);
                }
            });
        } else {
            const elementWithError = document.getElementById(valid.elementId)
                ?.parentElement?.parentElement;
            if (elementWithError !== undefined && elementWithError !== null) {
                scrollIntoView(elementWithError, document.body, {
                    behavior: "smooth",
                });
            }
        }
    };

    const renderActionButtons = () => {
        return (
            <div className="sat__form-buttons">
                <Button
                    disabled={isSubmittingForm}
                    onClick={e => handleBack(e)}
                    anchorText="Previous step"
                    outlineButton={true}
                    iconLeft={true}
                    iconClass="icon-arrow-left"
                    className="prev"
                />
                <Button
                    disabled={isSubmittingForm}
                    onClick={e =>
                        formStepUrlParameter === totalSections - 1
                            ? handleSubmit(e)
                            : handleNext(e)
                    }
                    anchorText={
                        formStepUrlParameter < totalSections - 1
                            ? "Next"
                            : isSubmittingForm
                            ? "Submitting"
                            : "Submit"
                    }
                    outlineButton={false}
                    iconLeft={false}
                    iconClass={
                        // update loading state
                        isSubmittingForm
                            ? "icon-circle-notch-solid icon-spinner"
                            : "icon-arrow-right"
                    }
                    className="next"
                />
                <div>
                    {isSubmittingForm && (
                        <div className="sat__form-submit-message">
                            <i className="icon icon-check invite-status" />
                            <p className="m-0">
                                Generating report, please wait. Do not hit
                                refresh or close browser.
                            </p>
                        </div>
                    )}
                    <a
                        className={`sat__form-container-link ${
                            isSubmittingForm &&
                            "sat__form-container-link--disabled"
                        }`}
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            isSubmittingForm || setShowModal("save");
                        }}
                    >
                        Save and continue later
                    </a>
                </div>
            </div>
        );
    };

    return (
        <>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
            />
            <div className="sat__section-intro">
                <h3 tabIndex={0} id="sat-intro-step">
                    Step {section + 1}
                </h3>
                <h2 tabIndex={0} id="sat-intro-title">
                    {name}
                </h2>
                <FormIntro content={intro} tooltip={toolip} />
                <img src={img} alt="self assessment questionnaire" />
            </div>
            <div id="sat-form-container">
                <div className="sat__form-container">
                    {formStepUrlParameter === 1 ? (
                        <>
                            <BusinessEntity
                                tooltip={toolip}
                                submitErrors={submitErrors}
                            />
                        </>
                    ) : null}
                    {formStepUrlParameter === 0 ? (
                        <>
                            <AboutYou
                                submitErrors={submitErrors}
                                disabled={isSubmittingForm}
                            />
                        </>
                    ) : (
                        questions.map((question: FormQuestion, i: number) => {
                            const { tooltip, _uid, dependent } = question;
                            const answer = question.answer[0];
                            const submitError = submitErrors.find(
                                (error: Error) => error.inputId === _uid
                            );

                            return dependent ? (
                                showDependent && (
                                    <div
                                        className={`sat__form-item sat__form-item--step-${
                                            section + 1
                                        }--question-${i + 3}`}
                                        key={question._uid}
                                    >
                                        <p className="sat__form-item__question-no">
                                            Question {i + 3}
                                        </p>
                                        <div className="sat__form-item__markup">
                                            <ReturnMarkup
                                                content={question.question}
                                                tooltip={tooltip}
                                            />
                                            {returnInput(
                                                answer,
                                                _uid,
                                                dependent,
                                                submitError,
                                                isSubmittingForm
                                            )}
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div
                                    className={`sat__form-item sat__form-item--step-${
                                        section + 1
                                    }--question-${
                                        formStepUrlParameter === 1
                                            ? i + 3
                                            : i + 1
                                    }`}
                                    key={question._uid}
                                >
                                    <p className="sat__form-item__question-no">
                                        Question{" "}
                                        {formStepUrlParameter === 1
                                            ? i + 3
                                            : i + 1}
                                    </p>
                                    <div className="sat__form-item__markup">
                                        <ReturnMarkup
                                            content={question.question}
                                            tooltip={tooltip}
                                        />
                                        {returnInput(
                                            answer,
                                            _uid,
                                            "",
                                            submitError
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}

                    {section > 0 &&
                        !(formStepUrlParameter === totalSections - 1) &&
                        renderActionButtons()}
                </div>
                {formStepUrlParameter === 0 && (
                    <div className="sat__form-container mt-1">
                        <AboutYouTerms
                            submitErrors={submitErrors}
                            disabled={isSubmittingForm}
                        />
                        <Button
                            disabled={isSubmittingForm}
                            onClick={e => handleNext(e)}
                            anchorText={"Next"}
                            outlineButton={false}
                            iconLeft={false}
                            iconClass={
                                // update loading state
                                isSubmittingForm
                                    ? "icon-circle-notch-solid icon-spinner"
                                    : "icon-arrow-right"
                            }
                            className="next"
                        />
                    </div>
                )}
                {formStepUrlParameter === totalSections - 1 && (
                    <div className="sat__form-container mt-1">
                        <EmailConfirmation />
                        {renderActionButtons()}
                    </div>
                )}
            </div>
        </>
    );
};

interface Error {
    inputId: string;
    error: string;
}

interface AboutYouProps {
    submitErrors: Error[];
    disabled?: boolean;
}

const AboutYou: React.FC<AboutYouProps> = ({ submitErrors, disabled }) => {
    return (
        <div id="sat-form-non-standard">
            <div className="sat__form-item sat__form-item--step-1--question-first-name">
                <div className="sat__form-item__markup">
                    <TextInput
                        label="First name"
                        placeholder="First name"
                        required={true}
                        disabled={disabled}
                        type="text"
                        questionId="first-name-q"
                        answerId="first-name-a"
                        submitError={submitErrors.find(
                            error => error.inputId === "first-name-a"
                        )}
                    />
                </div>
            </div>
            <div className="sat__form-item sat__form-item--step-1--question-last-name">
                <div className="sat__form-item__markup">
                    <TextInput
                        label="Last name"
                        placeholder="Last name"
                        required={true}
                        disabled={disabled}
                        type="text"
                        questionId="last-name-q"
                        answerId="last-name-a"
                        submitError={submitErrors.find(
                            error => error.inputId === "last-name-a"
                        )}
                    />
                </div>
            </div>
            <div className="sat__form-item sat__form-item--step-1--question-email">
                <div className="sat__form-item__markup">
                    <TextInput
                        label="Email address"
                        placeholder="email@example.com"
                        required={true}
                        disabled={disabled}
                        type="email"
                        subText="Your email will be used to save and continue your submission and to send your results upon completion."
                        questionId="email-q"
                        answerId="email-a"
                        submitError={submitErrors.find(
                            error => error.inputId === "email-a"
                        )}
                    />
                </div>
            </div>
            <div className="sat__form-item sat__form-item--step-1--question-phone">
                <div className="sat__form-item__markup">
                    <TextInput
                        label="Your primary work phone number (optional)"
                        placeholder="e.g. 0400000000"
                        required={false}
                        disabled={disabled}
                        type="tel"
                        subText="We collect your work phone number in case we need to contact you to provide support or assistance."
                        questionId="phone-q"
                        answerId="phone-a"
                        submitError={submitErrors.find(
                            error => error.inputId === "phone-a"
                        )}
                    />
                </div>
            </div>
            <div className="sat__form-item sat__form-item--step-1--question-role">
                <div className="sat__form-item__markup">
                    <p>
                        <b>
                            Which best describes your role in your
                            organisation?*
                        </b>
                    </p>
                    <div id="radio-input-non-standard">
                        <RadioInput
                            options={[
                                {
                                    label: "Employee or Volunteer",
                                    score: "1",
                                    value: "employee-or-volunteer",
                                    behaviour: "none",
                                    _uid: "employee-or-volunteer-1",
                                },
                                {
                                    label: "Manager or Supervisor or Team Leader",
                                    score: "2",
                                    value: "manager-or-supervisor-or-team-leader",
                                    behaviour: "none",
                                    _uid: "manager-or-supervisor-or-team-leader-2",
                                },
                                {
                                    label: "Human Resources Team",
                                    score: "3",
                                    value: "human-resources-team",
                                    behaviour: "none",
                                    _uid: "human-resources-team-3",
                                },
                                {
                                    label: "Executive or Board Member",
                                    score: "4",
                                    value: "executive-or-board-member",
                                    behaviour: "none",
                                    _uid: "executive-or-board-member-4",
                                },
                            ]}
                            questionId="role-q"
                            submitError={submitErrors.find(
                                error => error.inputId === "role-a"
                            )}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface AboutYouTermsProps {
    submitErrors: Error[];
    disabled?: boolean;
}

const AboutYouTerms: React.FC<AboutYouTermsProps> = ({
    submitErrors,
    disabled,
}) => {
    const navigate = useNavigate();

    return (
        <div className="sat__form-item__markup sat__form-item__about mb-2">
            <div id="checkbox-input-non-standard">
                <p>
                    Your responses to this self-assessment hold no legal or
                    regulatory implications for you or your organisation. By
                    completing this self-assessment, you give consent to the
                    Office of the Children's Guardian to analyse and use your
                    data to evaluate the Child Safe Scheme and identify sectors
                    or organisations for additional feedback and support.
                </p>
                <CheckboxInput
                    options={[
                        {
                            label: "Yes, I understand.",
                            score: "1",
                            value: "true",
                            behaviour: "none",
                            _uid: "consent-1",
                        },
                    ]}
                    disabled={disabled}
                    questionId="consent-q"
                    submitError={submitErrors.find(
                        error => error.inputId === "consent-q"
                    )}
                />
            </div>
            <p className="sat__form-item-sub">
                More than one person in your organisation can complete the
                self-assessment. It is recommended that any person who completes
                the self-assessment has some knowledge of child safe practice
                and is able to answer questions regarding your organisation.
            </p>
            <p className="sat__form-item-sub">
                For further information please{" "}
                <a
                    style={{ cursor: "pointer" }}
                    tabIndex={0}
                    onClick={() => {
                        navigate("/faq");
                    }}
                >
                    read more here
                </a>
                .
            </p>
        </div>
    );
};

const EmailConfirmation: React.FC = () => {
    return (
        <div className="sat__form-item__markup sat__form-item__about mb-2">
            <div className="sat__form-item  sat__form-item--step-14--question-email-confirm">
                <h3 className="sat__form-item__question-no">
                    Confirmation of email address:
                </h3>
                <p className="mb-1">
                    Please check the email address you have provided below. Your
                    assessment report will be sent to this email address. If the
                    email address is incorrect, please update before submitting.
                </p>
                <TextInput
                    label="Send my assessment report to"
                    placeholder="email@example.com"
                    required={false}
                    disabled={false}
                    type="email"
                    questionId="email-q"
                    answerId="email-a"
                />
            </div>
        </div>
    );
};

interface BusinessEntityProps {
    submitErrors: Error[];
    tooltip: Tooltip[];
}

const BusinessEntity: React.FC<BusinessEntityProps> = ({
    submitErrors,
    tooltip,
}) => {
    const toolTipRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    return (
        <div id="sat-form-non-standard">
            <div
                className={`sat__form-item sat__form-item--step-2--question-1`}
            >
                <p className="sat__form-item__question-no">Question 1</p>
                <div className="sat__form-item__markup">
                    <div className="position-relative">
                        <p ref={toolTipRef} className="mb-1">
                            Enter your organisation&apos;s ABN and select your
                            organisation&apos;s business or entity name. Some
                            tips on finding this information.
                        </p>
                        {tooltip.length > 0 && (
                            <Tooltip
                                tooltip={tooltip}
                                node={toolTipRef}
                                htmlContent="Enter your organisation's ABN and select your
                    organisation's business or entity name. Some tips on finding this information."
                            />
                        )}
                    </div>
                    <label>ABN*</label>
                    <p className="mt-025">
                        Unsure of your organisation&apos;s ABN? Use the
                        Australian Government&apos;s ABN Lookup here -{" "}
                        <a href="https://abr.business.gov.au/" target="_blank">
                            https://abr.business.gov.au/
                        </a>
                    </p>
                    <div className="position-relative" id="abn-q-container">
                        <TextInput
                            placeholder="e.g. 47 294 985 832"
                            required={true}
                            disabled={false}
                            type="text"
                            questionId="abn-q"
                            answerId="abn-a"
                            abnLookupAction={true}
                            submitError={submitErrors.find(
                                error => error.inputId === "abn-a"
                            )}
                            businessNameSubmitError={submitErrors.find(
                                error => error.inputId === "business-name-a"
                            )}
                        />
                    </div>
                    <div className="mt-1">
                        <TextInput
                            label="Selected Business / Entity Name"
                            required={true}
                            disabled={true}
                            type="text"
                            questionId="business-name-q"
                            answerId="business-name-a"
                        />
                    </div>
                    <div className="mt-1">
                        <TextInput
                            label="Location Or Business Unit"
                            required={false}
                            disabled={false}
                            type="text"
                            questionId="location-or-business-unit-q"
                            answerId="location-or-business-unit-a"
                        />
                    </div>
                </div>
            </div>
            <div
                className={`sat__form-item sat__form-item--step-2--question-2`}
            >
                <p className="sat__form-item__question-no mt-3">Question 2</p>
                <div className="sat__form-item__markup">
                    <p>
                        Please provide your organisation&apos;s primary address.
                        If your organisation does not have a fixed address,
                        please provide a suitable postal address
                    </p>
                    <TextInput
                        label="Street Address"
                        required={true}
                        disabled={false}
                        type="text"
                        questionId="street-address-q"
                        answerId="street-address-a"
                        submitError={submitErrors.find(
                            error => error.inputId === "street-address-a"
                        )}
                    />
                </div>
                <div className="sat__form-item__markup sat__form-item__markup-responsive mt-1">
                    <div className="sat__form-item__markup-responsive-item">
                        <TextInput
                            label="Apartment/Suite number"
                            required={false}
                            disabled={false}
                            type="text"
                            questionId="street-address-suite-apt-q"
                            answerId="street-address-suite-apt-a"
                            submitError={submitErrors.find(
                                error =>
                                    error.inputId ===
                                    "street-address-suite-apt-a"
                            )}
                        />
                    </div>
                    <div className="sat__form-item__markup-responsive-item">
                        <TextInput
                            label="Suburb"
                            required={true}
                            disabled={false}
                            type="text"
                            questionId="suburb-q"
                            answerId="suburb-a"
                            submitError={submitErrors.find(
                                error => error.inputId === "suburb-a"
                            )}
                        />
                    </div>
                </div>
                <div className="sat__form-item__markup sat__form-item__markup-responsive mt-1">
                    <div className="sat__form-item__markup-responsive-item">
                        <label style={{ marginBottom: "0.5rem" }}>
                            State *
                        </label>
                        <SelectInput
                            questionId="state-q"
                            options={[
                                {
                                    _uid: "state-q-nsw",
                                    value: "NSW",
                                    label: "NSW",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-vic",
                                    value: "VIC",
                                    label: "VIC",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-sa",
                                    value: "SA",
                                    label: "SA",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-qld",
                                    value: "QLD",
                                    label: "QLD",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-act",
                                    value: "ACT",
                                    label: "ACT",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-wa",
                                    value: "WA",
                                    label: "WA",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-nt",
                                    value: "NT",
                                    label: "NT",
                                    behaviour: "none",
                                    score: "",
                                },
                                {
                                    _uid: "state-q-tas",
                                    value: "TAS",
                                    label: "TAS",
                                    behaviour: "none",
                                    score: "",
                                },
                            ]}
                            submitError={submitErrors.find(
                                error => error.inputId === "state-q"
                            )}
                        />
                    </div>
                    <div className="sat__form-item__markup-responsive-item">
                        <TextInput
                            label="Post Code"
                            required={true}
                            disabled={false}
                            type="text"
                            questionId="postcode-q"
                            answerId="postcode-a"
                            numberOnly={true}
                            submitError={submitErrors.find(
                                error => error.inputId === "postcode-a"
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
