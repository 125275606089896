import type React from "react";
import { Helmet } from "react-helmet";
import { scrollTo } from "scroll-js";
import HeroThankyou from "@js/organisms/HeroThankyou";
import { Button } from "@js/atoms/Button";
import {
    NewsArticle,
    ThankyouSubFooterHeader,
} from "@js/molecules/StoryBlokComponents";
import { useStoryBlok } from "@js/slices/sliceStoryBlok";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useForm } from "@js/slices/sliceForm";

const Thankyou: React.FC = () => {
    const navigate = useNavigate();
    const { jsonData } = useStoryBlok();
    const subFooterData = jsonData?.story?.content;
    const newsArticleData = subFooterData?.body as Article[];
    const { entryId } = useForm();

    useEffect(() => {
        scrollTo(window, { top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (!entryId) {
            navigate("/");
        }
    });

    const sendMail = () => {
        //TODO: need email subject line and body
        const subject = "Complete the OCG's Child Safe Self-Assessment";
        const body = [
            "You've been invited to complete to OCG's Child Safe Self-Assessment to help keep children safe in organisations.",
            "The self-assessment will help your organisation identify areas of strength and opportunities for improvement in implementing the NSW Child Safe Standards. Based on your responses, we'll generate a personalised Assessment and Action Report including useful resources. This report will help your organisation improve its child safety for each Standard.",
            `Visit ${window.location.host} to get started. It will only take about 30 minutes and you can save and continue later!`,
        ].join("%0D%0A%0D%0A");
        const outboundUrl = `mailto:''?subject=${subject}&body=${body}`;
        window.open(outboundUrl, "_blank");
    };

    const handleLinkedInShare = () => {
        const outboundUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.origin}`;
        window.open(outboundUrl, "_blank");
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children&apos;s Guardian | Self Assessment |
                    Thank you for completing the self assessment!
                </title>
            </Helmet>
            <div className="thankyou">
                <HeroThankyou />
                <div className="thankyou__body">
                    <div className="thankyou__body__inner">
                        <h2 tabIndex={0}>Share the self assessment tool</h2>
                        <div className="thankyou__body__inner__item">
                            <i className="icon icon-building t-icon" />
                            <div>
                                <h3 tabIndex={0}>
                                    Invite colleagues within your organisation
                                </h3>
                                <p tabIndex={0}>
                                    Invite a colleague and share your responses
                                    to the organisation questions to help them
                                    save time completing their own self
                                    assessment!
                                </p>
                                <p tabIndex={0}>
                                    Larger organisations that have different
                                    business units, services or operating
                                    environments may benefit from doing a
                                    self-assessment for each unit or service.
                                </p>
                                <Button
                                    onClick={() => navigate("/invite")}
                                    anchorText="Send Invite to Colleagues"
                                    outlineButton={false}
                                />
                            </div>
                        </div>
                        <div className="thankyou__body__inner__item">
                            <i className="icon icon-paper-plane t-icon" />
                            <div>
                                <h3 tabIndex={0}>
                                    Encourage your network to complete the self
                                    assessment
                                </h3>
                                <p tabIndex={0}>
                                    Let your network know that you have
                                    completed OCG&apos;s Child Safe Self
                                    Assessment or send an email to encourage
                                    others in your network to complete their own
                                    Self Assessment!
                                </p>
                                <Button
                                    onClick={() => {
                                        handleLinkedInShare();
                                    }}
                                    iconClass={"icon-linkedin"}
                                    iconLeft={true}
                                    anchorText="Share on Linkedin"
                                    outlineButton={true}
                                    className="mr-1 no-animate"
                                />
                                <Button
                                    onClick={() => {
                                        sendMail();
                                    }}
                                    iconClass={"icon-mail"}
                                    iconLeft={true}
                                    anchorText="Share via email"
                                    outlineButton={true}
                                    className="no-animate"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="thankyou__subfooter">
                    <div className="thankyou__subfooter__inner">
                        {subFooterData ? (
                            <ThankyouSubFooterHeader
                                title={subFooterData.title}
                                view_all_cta_link={
                                    subFooterData.view_all_cta_link
                                }
                            />
                        ) : (
                            <h2>Loading</h2>
                        )}

                        <div className="thankyou__subfooter__articles">
                            {newsArticleData ? (
                                newsArticleData.map(article => {
                                    return (
                                        <NewsArticle
                                            key={article._uid}
                                            image={article.image}
                                            title={article.title}
                                            body={article.body}
                                            cta_link={article.cta_link}
                                        />
                                    );
                                })
                            ) : (
                                <>
                                    <div className="loader block m1" />
                                    <div className="loader block m1" />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Thankyou;
