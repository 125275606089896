import type React from "react";
import { useEffect, useState } from "react";
import { scrollTo } from "scroll-js";
import { Helmet } from "react-helmet";
import HeroInvite from "@js/organisms/HeroInvite";
import { useNavigate } from "react-router-dom";
import { useInvite } from "@js/slices/sliceInvite";
import { useForm } from "@js/slices/sliceForm";

const Invite: React.FC = () => {
    const [emails, setEmails] = useState([""]);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { sendInvite, inviteStatus } = useInvite();
    const { entryId } = useForm();

    useEffect(() => {
        if (!entryId) {
            navigate("/");
        }
        scrollTo(window, { top: 0, behavior: "smooth" });
    }, []);

    const validateEmail = (value: string) => {
        const regex =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (!regex.test(value)) {
            setError("Please ensure all email addresses are valid");
        } else setError(null);
    };

    const validateAll = (updatedEmails?: string[]) => {
        const testEmails = updatedEmails ? updatedEmails : emails;

        testEmails.forEach((email: string) => {
            if (email === "") {
                setError("Please ensure all email addresses are valid");
            } else setError(null);
        });
    };

    const handleAddInvite = (e: React.MouseEvent) => {
        e.preventDefault();
        const emailContainsEmptyRecord = emails.includes("");
        if (error || emailContainsEmptyRecord) return;
        const updateEmails = [...emails];
        updateEmails.push("");
        setEmails(updateEmails);
        setError("Please ensure all email addresses are valid");
    };

    const handleRemoveInvite = (e: React.MouseEvent, index: number) => {
        e.preventDefault();
        const updateEmails = [...emails];
        updateEmails.splice(index, 1);

        setEmails(updateEmails);
        validateAll(updateEmails);
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.code === "Enter") {
            const updateEmails = [...emails];
            updateEmails.splice(index, 1);
            validateAll(updateEmails);
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.stopPropagation();
        e.preventDefault();
        sendInvite(emails);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        i: number
    ) => {
        const val = e.target.value;
        validateEmail(val);
        const updateEmails = [...emails];
        updateEmails.splice(i, 1, val);
        setEmails(updateEmails);
    };

    useEffect(() => {
        const findDuplicates = (arr: string[]) =>
            arr.filter(
                (item: string, index: number) => arr.indexOf(item) != index
            );
        const duplicateEmail = findDuplicates(emails);
        if (duplicateEmail.length > 0) {
            setError(`Please remove duplicate emails: ${duplicateEmail}`);
        }
    }, [emails]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children&apos;s Guardian | Self Assessment |
                    Invite Colleagues
                </title>
            </Helmet>
            <div>
                <HeroInvite />
                <div className="invite__body">
                    <div className="invite__body__inner">
                        <h2 tabIndex={0}>Send invite/s to:</h2>
                        <div className="invite__body__inner__form">
                            <form id="invite-form" onSubmit={handleSubmit}>
                                <label tabIndex={0} htmlFor="email">
                                    Email address
                                </label>
                                {error && (
                                    <div
                                        style={{
                                            marginTop: 2,
                                            width: "calc(100% - 33px",
                                        }}
                                        className="on-change-valid"
                                    >
                                        {error}
                                    </div>
                                )}
                                {emails.map((inputVal: string, i: number) => {
                                    return (
                                        <div
                                            className="invite__body__inner__form-email"
                                            key={`email-invite-${i}`}
                                        >
                                            <input
                                                placeholder="invite@example.com.au"
                                                type="email"
                                                name={`email-${i}`}
                                                style={{
                                                    width:
                                                        emails.length > 0
                                                            ? "calc(100% - 33px)"
                                                            : "",
                                                }}
                                                value={inputVal}
                                                onChange={e => {
                                                    handleChange(e, i);
                                                }}
                                            />
                                            {i !== 0 && (
                                                <i
                                                    className="icon icon-close"
                                                    tabIndex={0}
                                                    onClick={e =>
                                                        handleRemoveInvite(e, i)
                                                    }
                                                    onKeyDown={e =>
                                                        handleKeyDown(e, i)
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                                {emails.length < 30 && (
                                    <a
                                        href="#"
                                        onClick={e => handleAddInvite(e)}
                                        style={{
                                            cursor:
                                                error || emails.includes("")
                                                    ? "not-allowed"
                                                    : "pointer",
                                        }}
                                    >
                                        <i className="icon icon-plus" />
                                        <span>Add another email</span>
                                    </a>
                                )}

                                <input
                                    className="button"
                                    type="submit"
                                    value={`Send Invite${
                                        emails.length > 0 ? "s" : ""
                                    }`}
                                    disabled={
                                        error ||
                                        emails.length === 0 ||
                                        emails.includes("")
                                            ? true
                                            : false
                                    }
                                />
                                <p className="mt-0">
                                    {inviteStatus === "Invitations sent!" && (
                                        <i className="icon icon-check invite-status" />
                                    )}
                                    {inviteStatus !== "" && inviteStatus}
                                </p>
                            </form>
                        </div>
                        <div className="invite__body__inner__item">
                            <div>
                                <h2 tabIndex={0}>
                                    What information will be shared?
                                </h2>
                                <p tabIndex={0}>
                                    Only the organisation questions you answered
                                    in step two will be shared with your invited
                                    colleague. This is to save them time
                                    completing their self-assessment. They can
                                    edit any of these responses for their
                                    submission if they want to.
                                </p>
                                <p tabIndex={0}>
                                    <strong>
                                        Your personal information will not be
                                        shared;
                                    </strong>{" "}
                                    your responses to the 10 Child Safe
                                    Standards will not be shared. Please note,
                                    if you make any later changes to your
                                    responses for the organisation questions,
                                    these updates will not be shared with your
                                    colleague.
                                </p>
                                <p tabIndex={0}>
                                    <strong>
                                        You won't be notified if the user has
                                        started or finished a self-assessment,
                                    </strong>{" "}
                                    and you won't be able to see their
                                    Assessment and Action Report.
                                </p>
                                <p tabIndex={0}>
                                    For further information please{" "}
                                    <a
                                        style={{ cursor: "pointer" }}
                                        tabIndex={0}
                                        onClick={() => {
                                            navigate("/faq");
                                        }}
                                    >
                                        read more here
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Invite;
