import type React from "react";
import Loader from "@js/atoms/Loader";
import HeroLanding from "@js/organisms/HeroLanding";
import img from "@assets/img/illustration-questionnaire.svg";

export const LoaderHome: React.FC = () => {
    return (
        <div className="landing">
            <HeroLanding />
            <div className="landing__body">
                <div className="landing__body__inner">
                    <Loader loaderSize="large" className="mt-3" />
                    <Loader className="mt-2" />
                    <Loader />
                    <Loader className="mt-2" />
                    <Loader repeat={2} />
                    <Loader className="mt-3" />
                    <Loader loaderSize="block" />
                    <Loader repeat={2} />
                    <Loader className="mt-3" />
                    <Loader loaderSize="large" repeat={3} />
                    <Loader className="mt-3" />
                    <Loader loaderSize="large" repeat={2} />
                    <Loader className="mt-3" />
                    <Loader loaderSize="large" />
                </div>
            </div>
        </div>
    );
};

export const LoaderFaq: React.FC = () => {
    return (
        <div className="faq">
            <div className="faq__inner">
                <h1>FAQs</h1>
                <Loader className="mt-3" />
                <Loader loaderSize="large" repeat={5} />
                <Loader className="mt-2" />
                <Loader loaderSize="large" repeat={5} />
                <Loader className="mt-2" />
                <Loader loaderSize="large" />
            </div>
        </div>
    );
};

export const LoaderForm: React.FC = () => {
    return (
        <div className="sat">
            <div className="sat__inner">
                <div className="sat__section-intro">
                    <h3>Step 1</h3>
                    <h2 id="sat-intro-title">Loading</h2>
                    <p>
                        Please wait while we fetch the questionnaire, it will
                        only take a second.
                    </p>
                    <img src={img} alt="self assessment questionnaire" />
                </div>
                <div>
                    <div className="sat__form-container">
                        <div className="sat__form-item">
                            <div className="sat__form-item__markup">
                                <Loader />
                                <Loader loaderSize="large" repeat={2} />
                                <Loader className="mt-3" />
                                <Loader loaderSize="large" />
                                <Loader className="mt-3" />
                                <Loader loaderSize="large" repeat={3} />
                                <Loader className="mt-3" />
                                <Loader />
                                <Loader loaderSize="large" repeat={2} />
                                <Loader className="mt-3" />
                                <Loader />
                                <Loader loaderSize="large" />
                            </div>
                        </div>
                    </div>
                    <div className="sat__form-container mt-1">
                        <div className="sat__form-item">
                            <div className="sat__form-item__markup">
                                <Loader />
                                <Loader loaderSize="large" repeat={2} />
                                <Loader className="mt-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
