import type React from "react";
import { useState, useEffect } from "react";
import { HeaderLogo } from "@js/atoms/Logos";
import HeaderProgressBar from "@js/molecules/HeaderProgressBar";
import { useForm } from "@js/slices/sliceForm";
import { useWeglot } from "@js/slices/sliceWeglot";
import { useWindowSize } from "@js/hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector } from "@js/store/hooks";

const Header: React.FC = () => {
    const isSubmittingForm = useAppSelector(
        state => state.form.isSubmittingForm
    );
    const { setSection, section, apiError, setApiError } = useForm();
    const windowSize = useWindowSize();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const notFormPage = pathname.toLowerCase().indexOf("self-assessment") < 0;
    const thankYouPage = pathname.toLowerCase().indexOf("thankyou") !== -1;

    const handleBack = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const prevPage = section - 1;
        setSection(section - 1);
        navigate(`/self-assessment/${prevPage}`);
    };

    return (
        <div id="header" className="header">
            {apiError && (
                <div className="header__error">
                    {apiError.message}
                    <i
                        className="icon icon-close"
                        onClick={() => setApiError()}
                    />
                </div>
            )}
            <a className="header__skip-link" href={`${pathname}#content`}>
                Skip to content
            </a>
            <div className="row middle-xs between-xs header__container">
                <div
                    onClick={() => {
                        navigate("/");
                    }}
                    className="col-xs header__logo-container"
                >
                    <HeaderLogo />
                </div>
                {/^(\/faq|\/invite)$/.test(pathname) && (
                    <div className="header__link-container-back">
                        <HeaderLink
                            url={pathname === "/invite" ? "/thankyou" : "/"}
                            onClick={() => navigate(-1)}
                            anchorText="Back"
                            iconClass="icon-arrow-left"
                        />
                    </div>
                )}
                <div className="header__link-container-m">
                    <HeaderLink
                        url="/faq"
                        anchorText="Need help?"
                        iconClass="icon-square-question-solid"
                    />
                    <LanguageSelect />
                </div>
                <div
                    style={{
                        borderTop: notFormPage ? 0 : "",
                    }}
                    className="col-xs-12 col-sm px-0 col-md header__progress-border"
                >
                    <div className="row mx-0">
                        {notFormPage
                            ? null
                            : section > 0 && (
                                  <div
                                      className={`col-xs px-0 header__previous-step-container ${
                                          isSubmittingForm
                                              ? "header__previous-step-container--disabled"
                                              : ""
                                      }`}
                                      onClick={(
                                          e: React.MouseEvent<HTMLDivElement>
                                      ) => isSubmittingForm || handleBack(e)}
                                  >
                                      <HeaderPreviousStep />
                                  </div>
                              )}

                        <div
                            style={
                                windowSize[0] < 768 && notFormPage
                                    ? { display: "none" }
                                    : {}
                            }
                            className="col-xs header__progress-bar-container"
                        >
                            <div
                                style={
                                    notFormPage && !thankYouPage
                                        ? {
                                              top: 0,
                                              bottom: 0,
                                              margin: "auto",
                                              height: 27,
                                          }
                                        : {}
                                }
                                className="header__link-container"
                            >
                                <HeaderLink
                                    url="/faq"
                                    anchorText="Need help?"
                                    iconClass="icon-square-question-solid"
                                />
                                <div className="header__language-select-container">
                                    <LanguageSelect />
                                </div>
                            </div>
                            {notFormPage && !thankYouPage ? null : (
                                <HeaderProgressBar />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;

interface HeaderLinkProps {
    url: string;
    anchorText: string;
    iconClass: string;
    onClick?: () => void;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({
    url,
    anchorText,
    iconClass,
    onClick,
}) => {
    const navigate = useNavigate();

    return (
        <a
            className="header__link"
            onClick={e => {
                e.preventDefault();
                onClick ? onClick() : navigate(url);
            }}
            href={url}
        >
            <i className={"icon " + iconClass}></i>
            <span>{anchorText}</span>
        </a>
    );
};

const LanguageSelect: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [lang, setLang] = useState<string>("");
    const [docHasClick, setDocHasClick] = useState<boolean>(false);
    const { languages, initialized } = useWeglot();
    const returnLang = (language: string) => {
        switch (language) {
            case "en":
                return "English";
            case "hi":
                return "Hindi";
            case "ar":
                return "Arabic";
            case "ko":
                return "Korean";
            case "tw":
                return "Chinese (Traditional)";
            case "zh":
                return "Chinese (Simplified)";
            default:
                return "English";
        }
    };

    const handleClick = () => {
        if (!docHasClick) {
            setShow(!show);
        }
    };

    const handleLanguageChange = (language: string) => {
        setShow(false);
        window.Weglot.switchTo(language);
        setLang(language);
    };

    const handleKeyboardEvent = (e: React.KeyboardEvent, language: string) => {
        if (e.key === "Enter") {
            setShow(!show);
            window.Weglot.switchTo(language);
            setLang(language);
        } else if (e.key === "Escape") {
            setShow(!show);
        }
    };

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setShow(!show);
        }
    };

    const handleClickWindow = () => {
        setShow(false);
    };

    useEffect(() => {
        if (show === true) {
            document.addEventListener("keydown", handleHideDropdown, true);
            document.addEventListener("click", handleClickWindow, true);
            setDocHasClick(true);
        }
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickWindow, true);
            setDocHasClick(false);
        };
    });

    return (
        <div className="header__language-select">
            <a
                tabIndex={0}
                className="header__link position-relative"
                onClick={e => {
                    e.preventDefault();
                    handleClick();
                }}
                onKeyDown={e => {
                    e.key === "Enter" && setShow(!show);
                }}
            >
                <i className="icon icon-globe" />
                <span>{returnLang(initialized ? lang : "en")}</span>
                <i
                    className={`icon icon-chevron-down header__language-select-down ${
                        show ? " rotate" : ""
                    }`}
                />
            </a>
            <div className={`header__link-language${show ? " show" : ""}`}>
                {languages.map((language: string, i) => {
                    return (
                        <p
                            tabIndex={0}
                            className={
                                language === window.Weglot.getCurrentLang()
                                    ? "active"
                                    : ""
                            }
                            onKeyDown={e => {
                                handleKeyboardEvent(e, language);
                            }}
                            onClick={() => {
                                handleLanguageChange(language);
                            }}
                            key={i}
                        >
                            {returnLang(language)}
                        </p>
                    );
                })}
                <small tabIndex={0}>
                    Please note, translation will only apply to the
                    questionnaire, the generated report with your results is
                    currently only available in English.
                </small>
            </div>
        </div>
    );
};

const HeaderPreviousStep: React.FC = () => {
    return (
        <div className="header__previous-step">
            <i className="icon icon-arrow-tail-left"></i>
            <p id="prev-step-element" tabIndex={0}>
                Previous step
            </p>
        </div>
    );
};
