import type React from "react";
import { useEffect } from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    createRoutesFromElements,
} from "react-router-dom";
import Layout from "@js/containers/Layout";
import Landing from "@js/containers/Landing";
import Form from "@js/containers/Form";
import Thankyou from "@js/containers/Thankyou";
import Faq from "@js/containers/Faq";
import Invite from "@js/containers/Invite";
import StoryBlokFetcher from "@js/containers/StoryBlokFetcher";
import Continue from "@js/containers/Continue";
import ErrorBoundary from "@js/containers/ErrorBoundary";
import Unsubscribe from "@js/containers/Unsubscribe";
import { useWeglot } from "@js/slices/sliceWeglot";
import SampleQuestionnaire from "@js/containers/SampleQuestionnaire";

declare let Weglot: Window["Weglot"];

const App: React.FC = (): React.ReactElement => {
    const { initialized, setInitialized, setLanguages } = useWeglot();
    useEffect(() => {
        if (initialized) {
            return;
        }
        const script = document.createElement("script");
        script.src = "https://cdn.weglot.com/weglot.min.js";
        script.onload = function () {
            Weglot.initialize({
                //Domain whitelist set up in Weglot settings dashboard
                api_key: process.env.REACT_APP_WEGLOT_API_KEY,
                cache: process.env.NODE_ENV === "development" ? false : true,
                hideSwitcher: true,
            });
            Weglot.on("initialized", () => {
                const availableLanguages = Weglot.options.languages
                    .map((language: WeglotLanguage) => {
                        return language.language_to;
                    })
                    .concat(Weglot.options.language_from);
                setInitialized(true);
                setLanguages(availableLanguages);
            });
        };
        document.head.appendChild(script);
    }, []);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                errorElement={<ErrorBoundary />}
                path="/"
                element={<Layout />}
            >
                <Route
                    path="/"
                    element={
                        <StoryBlokFetcher path="home">
                            <Landing />
                        </StoryBlokFetcher>
                    }
                />
                <Route
                    path="/self-assessment/:sectionNumber"
                    element={
                        <StoryBlokFetcher path="sat-form">
                            <Form />
                        </StoryBlokFetcher>
                    }
                />
                <Route
                    path="/self-assessment/"
                    element={
                        <StoryBlokFetcher path="sat-form">
                            <Form />
                        </StoryBlokFetcher>
                    }
                />
                <Route
                    path="continue"
                    element={
                        <StoryBlokFetcher path="sat-form">
                            <Continue />
                        </StoryBlokFetcher>
                    }
                />
                <Route
                    path="thankyou"
                    element={
                        <StoryBlokFetcher path="thank-you">
                            <Thankyou />
                        </StoryBlokFetcher>
                    }
                />
                <Route
                    path="faq"
                    element={
                        <StoryBlokFetcher path="help">
                            <Faq />
                        </StoryBlokFetcher>
                    }
                />
                <Route
                    path="sample-questionnaire"
                    element={<SampleQuestionnaire />}
                />
                <Route path="invite" element={<Invite />} />
                <Route path="unsubscribe" element={<Unsubscribe />} />
            </Route>
        )
    );

    return <RouterProvider router={router} />;
};

export default App;
