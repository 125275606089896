import type React from "react";
import logoColour from "@assets/img/logo-colour.svg";
import logoReverse from "@assets/img/logo-reverse.svg";

export const HeaderLogo: React.FC = () => {
    return (
        <img
            className="header-logo"
            src={logoColour}
            alt="Office of the Children's Guardian"
        />
    );
};

export const FooterLogo: React.FC = () => {
    return (
        <img
            className="footer-logo"
            src={logoReverse}
            alt="Office of the Children's Guardian"
        />
    );
};
