import type React from "react";
import { useState } from "react";
import { TextInput } from "@js/molecules/StoryBlokFormInputComponents";
import { Button } from "@js/atoms/Button";
import img from "@assets/img/illustration-thankyou.svg";
import { useForm } from "@js/slices/sliceForm";

const HeroThankyou: React.FC = () => {
    return (
        <>
            <div className="thankyou__hero">
                <div className="thankyou__hero__inner">
                    <div className="thankyou__hero__text-container">
                        <h1 tabIndex={0}>Thank you!</h1>
                        <h3 tabIndex={0}>
                            Please check your emails for your Assessment and
                            Action Report.
                        </h3>
                        <p tabIndex={0}>
                            Thank you for completing the Child Safe Self
                            Assessment. A link to your Assessment Report,
                            including a personalised action plan for your
                            organisation based on your responses, will be sent
                            to the nominated primary email address shortly.
                        </p>
                    </div>
                    <img src={img} alt="Child safe self assessment complete" />
                </div>
            </div>
            <CtaThankyou />
        </>
    );
};

export default HeroThankyou;

const CtaThankyou: React.FC = () => {
    const { formData, emailSubscribe } = useForm();
    const [isSubscribed, setIsSubscribed] = useState(false);
    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const email = formData.find((i: any) => i?.answerId === "email-a")
            ?.value as string;
        if (email) {
            emailSubscribe(email).then(() => {
                setIsSubscribed(true);
            });
        }
    };
    return (
        <div className="thankyou__cta">
            <div className="thankyou__cta__inner">
                <h2 tabIndex={0}>
                    Subscribe to updates about the{" "}
                    <span>Child Safe Scheme</span>
                </h2>
                <p tabIndex={0}>
                    Subscribe to receive information from the Office of the
                    Children's Guardian, including updates on the Child Safe
                    Standards.
                </p>
                {isSubscribed ? (
                    <div>
                        <h3>You have successfully subscribed to updates.</h3>
                    </div>
                ) : (
                    <div className="thankyou__cta__inner-email">
                        <TextInput
                            placeholder="email@example.com"
                            required={false}
                            disabled={false}
                            type="email"
                            questionId="email-q"
                            answerId="email-a"
                        />
                        <Button
                            onClick={handleSubmit}
                            anchorText="Subscribe"
                            outlineButton={false}
                            className="thankyou__cta__inner-save"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
