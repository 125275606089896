import type React from "react";
import { FooterLogo } from "@js/atoms/Logos";

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="row middle-xs top-sm between-xs start-sm footer__container">
                <div className="col-xs-12 col-sm footer__mission-container">
                    <FooterMission />
                </div>
                <hr className="footer__hr-x" />
                <div className="col-xs-12 col-sm footer__cta-container">
                    <hr className="footer__hr-y" />
                    <FooterCallToAction />
                </div>
            </div>
        </div>
    );
};

const FooterMission: React.FC = () => {
    return (
        <div className="footer__mission">
            <FooterLogo />
            <p>
                We regulate and oversee organisations in NSW to uphold children
                and young people’s right to be safe.
            </p>
        </div>
    );
};

const FooterCallToAction: React.FC = () => {
    return (
        <div className="footer__cta">
            <a href="https://ocg.nsw.gov.au/" target="_blank" rel="noreferrer">
                Learn more about the Child Safe Standards
                <i className="icon icon-arrow-right" />
            </a>
            <p>
                We pay respect to the Traditional Custodians and First Peoples
                of NSW, and acknowledge their continued connection to their
                country and culture.
            </p>
            <small>
                © 2022 Copyright Crown in right of the State of New South Wales
            </small>
        </div>
    );
};

export default Footer;
