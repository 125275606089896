import type React from "react";
import { Outlet } from "react-router-dom";
import Header from "@js/organisms/Header";
import Footer from "@js/organisms/Footer";
import Modal from "@js/organisms/Modal";
import { useModal } from "@js/slices/sliceModal";

const Layout: React.FC = (): React.ReactElement => {
    const { showModal } = useModal();
    return (
        <>
            <Modal show={showModal} />
            <Header />
            <div id="content" className="content">
                <Outlet />
            </div>
            <Footer />
        </>
    );
};

export default Layout;
