import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@js/store/hooks";

interface WeglotState {
    initialized: boolean;
    languages: Array<string>;
}

const initialState: WeglotState = {
    initialized: false,
    languages: [],
};

export const sliceWeglot = createSlice({
    name: "weglot",
    initialState,
    reducers: {
        setInitialized: (state, action) => {
            state.initialized = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = [...action.payload];
        },
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWeglot = () => {
    const dispatch = useAppDispatch();

    const initialized = useAppSelector(state => state.weglot.initialized);
    const languages = useAppSelector(state => state.weglot.languages);
    const setInitialized = (init: boolean) =>
        dispatch(sliceWeglot.actions.setInitialized(init));
    const setLanguages = (languages: [string]) => {
        dispatch(sliceWeglot.actions.setLanguages(languages));
    };

    return { initialized, languages, setInitialized, setLanguages };
};

export default sliceWeglot.reducer;
