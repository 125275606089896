import type React from "react";
import { useRef, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import StoryBlokClient from "storyblok-js-client";
import { ButtonLink } from "@js/atoms/Button";
import { useWindowSize } from "@js/hooks";

interface VideoProps {
    blok: {
        _uid: string;
        component: string;
        video_url: string;
        video_title: string;
        video_poster_image: string;
        _editable: string;
    };
}

interface CtaProps {
    blok: {
        _uid: string;
        component: string;
        title: string;
        subtitle: string;
        description: string;
        cta_label: string;
        _editable: string;
    };
}

interface NewsArticleProps {
    image: string;
    title: string;
    body: string;
    cta_link: string;
}

interface ThankyouProps {
    title: string;
    view_all_cta_link: string;
}

interface FormIntroProps {
    content: FormIntroContent;
    tooltip: Tooltip[];
}

interface MarkupProps {
    content: MarkupContent;
    tooltip: Tooltip[];
}

interface TooltipProps {
    tooltip: Tooltip[];
    node: React.MutableRefObject<HTMLInputElement>;
    htmlContent: string;
}

interface ButtonBlokProps {
    blok: {
        link_url: string;
        anchor_text: string;
        outline: boolean;
    };
}

// Initialise StoryBlok for rich text rendering
const Storyblok = new StoryBlokClient({});
Storyblok.setComponentResolver((component, blok) => {
    switch (component) {
        case "video_component":
            const video = <Video blok={blok} />;
            return ReactDOMServer.renderToStaticMarkup(video);
        case "take_self_assessment_component":
            const cta = <Cta blok={blok} />;
            return ReactDOMServer.renderToStaticMarkup(cta);
        case "button_component":
            const button = <ButtonBlok blok={blok} />;
            return ReactDOMServer.renderToStaticMarkup(button);
    }
});

export const Tooltip: React.FC<TooltipProps> = ({
    tooltip,
    node,
    htmlContent,
}) => {
    const [htmlTooltip, setHtmlTooltip] = useState<string>("");
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [offsetTop, setOffsetTop] = useState<number | undefined>(0);
    const [offsetLeft, setOffsetLeft] = useState<number | undefined>(0);
    const [pointerOffsetLeft, setPointerOffsetLeft] = useState<
        number | undefined
    >(0);
    const [width] = useWindowSize();

    const phrase = tooltip[0].phrase;
    const phraseId = tooltip[0]._uid;
    const tooltipId = tooltip[0]._uid + "-tooltip-body";

    const wrapKeywordWithHTML = (phrase: string) => {
        // inject this into the storyblok content
        return `<span id="${phraseId}" tabindex="0" class="sat__tooltip">${phrase}<i class="icon icon-info"></i></span>`;
    };

    const transformContent = (htmlContent: string, phrase: string) => {
        let temp = htmlContent;
        // find first match for phrase and wrap phrase in span
        const regex = new RegExp(phrase, "i");
        temp = temp.replace(regex, wrapKeywordWithHTML(phrase));
        return temp;
    };

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    const toggleTooltipKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
        if (e.key === "Enter") {
            setShowTooltip(!showTooltip);
        }
    };

    const addTooltip = () => {
        const el = node.current; // this is a ref for the storyblok content, passed in from parent
        if (el) {
            // get storyblok intro content, look for keyword, wrap with span element and add click listener
            el.innerHTML = transformContent(htmlContent, phrase);
            document
                .getElementById(phraseId)
                ?.addEventListener("click", toggleTooltip);
            document
                .getElementById(phraseId)
                ?.addEventListener("keydown", toggleTooltipKeyDown);
        }
    };

    useEffect(() => {
        if (showTooltip) {
            setShowTooltip(!showTooltip);
        }
        setHtmlTooltip(Storyblok.richTextResolver.render(tooltip[0].tooltip)); // this is the tooltip body content

        addTooltip(); // look for phrase in storyblok content, inject span element and add click listener

        // used for positioning tooltip body
        if (document.getElementById(phraseId) !== null) {
            const offsetTop = document.getElementById(phraseId)?.offsetTop;
            const offsetLeft = document.getElementById(phraseId)?.offsetLeft;
            setOffsetTop(offsetTop);
            if (width < 768 || (offsetLeft !== undefined && offsetLeft < 80)) {
                setOffsetLeft(0);
            } else {
                if (offsetLeft) {
                    setOffsetLeft(offsetLeft - 113);
                }
            }
        }
    }, [tooltip]);

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setShowTooltip(!showTooltip);
        }
    };

    const handleClickWindow = (e: MouseEvent) => {
        setShowTooltip(!showTooltip);
        e.stopImmediatePropagation();
    };

    useEffect(() => {
        if (showTooltip === true) {
            document.addEventListener("keydown", handleHideDropdown, true);
            document.addEventListener("click", handleClickWindow, true);
        }
        return () => {
            document.removeEventListener("keydown", handleHideDropdown, true);
            document.removeEventListener("click", handleClickWindow, true);
        };
    });

    useEffect(() => {
        // used for positioning tooltip body
        if (document.getElementById(phraseId) !== null) {
            const offsetLeft = document.getElementById(phraseId)?.offsetLeft;
            const offsetTop = document.getElementById(phraseId)?.offsetTop;
            setOffsetTop(offsetTop);
            if (width < 768 || (offsetLeft !== undefined && offsetLeft < 80)) {
                setOffsetLeft(0);
                setPointerOffsetLeft(offsetLeft);
            } else if (width && offsetLeft) {
                setOffsetLeft(offsetLeft - 113);
            }
        }
    }, [
        tooltip,
        width,
        document.getElementById(phraseId)?.offsetLeft,
        document.getElementById(phraseId)?.offsetTop,
    ]);

    useEffect(() => {
        const element = document.getElementById("tooltip-close");
        element?.focus();
    }, [showTooltip]);

    return (
        <div
            style={
                offsetTop !== undefined
                    ? {
                          left: offsetLeft,
                          top: offsetTop + 10,
                      }
                    : {}
            }
            className={`sat__tooltip-body${
                showTooltip ? " sat__tooltip-body-show" : ""
            }`}
            id={tooltipId}
        >
            <h4>{tooltip.length > 0 && tooltip[0].phrase}</h4>
            <i
                id="tooltip-close"
                onClick={toggleTooltip}
                onKeyDown={e => toggleTooltipKeyDown(e)}
                className="icon icon-close"
                tabIndex={0}
            />
            <div
                dangerouslySetInnerHTML={{
                    __html: htmlTooltip,
                }}
            />
            <div
                style={{ left: width < 768 ? pointerOffsetLeft : "" }}
                className="sat__tooltip-body-show-pointer"
            />
        </div>
    );
};

export const FormIntro: React.FC<FormIntroProps> = ({ content, tooltip }) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const htmlContent = Storyblok.richTextResolver.render(content);

    return (
        <>
            {tooltip.length > 0 && (
                <Tooltip
                    tooltip={tooltip}
                    node={ref}
                    htmlContent={htmlContent}
                />
            )}
            <div
                tabIndex={0}
                id="sat-intro-body"
                ref={ref}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
        </>
    );
};

export const ReturnMarkup: React.FC<MarkupProps> = ({ content, tooltip }) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const htmlContent = Storyblok.richTextResolver.render(content);

    return (
        <>
            {tooltip.length > 0 && (
                <Tooltip
                    tooltip={tooltip}
                    node={ref}
                    htmlContent={htmlContent}
                />
            )}
            <div
                id="story-markup-content"
                ref={ref}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
        </>
    );
};

// Define StoryBlok components here
const Video: React.FC<VideoProps> = ({ blok }) => {
    const videoID = blok.video_url.split("=")[1];

    return (
        <div className="landing__body__video-container">
            <div
                id="cover-image"
                className="landing__body__video-container-cover"
                style={{
                    backgroundImage: `url(${blok.video_poster_image})`,
                }}
            ></div>
            <div
                className="landing__body__video-container-play"
                id="play-button"
                tabIndex={0}
            />
            <iframe
                id="youtube-video"
                frameBorder="0"
                width="100%"
                src={`https://www.youtube.com/embed/${videoID}`}
                title={blok.video_title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
        </div>
    );
};

const Cta: React.FC<CtaProps> = ({ blok }) => {
    return (
        <div className="landing__body__cta-container">
            <h2 tabIndex={0}>{blok.title}</h2>
            <strong tabIndex={0}>{blok.subtitle}</strong>
            <p tabIndex={0}>{blok.description}</p>
            <ButtonLink
                url="/self-assessment"
                anchorText={blok.cta_label}
                outlineButton={false}
                className="landing__body__cta-link"
            />
        </div>
    );
};

const ButtonBlok: React.FC<ButtonBlokProps> = ({ blok }) => {
    return (
        <ButtonLink
            url={blok.link_url}
            anchorText={blok.anchor_text}
            outlineButton={blok.outline}
        />
    );
};

export const NewsArticle: React.FC<NewsArticleProps> = ({
    image,
    title,
    body,
    cta_link,
}) => {
    return (
        <div className="thankyou__subfooter__item">
            <div
                style={{ backgroundImage: `url(${image})` }}
                className="thankyou__subfooter__item-img"
            ></div>
            <div tabIndex={0} className="thankyou__subfooter__item-content">
                <h3 tabIndex={0}>{title}</h3>
                <p tabIndex={0}>{body}</p>
                <a href={cta_link} target="_blank" rel="noreferrer">
                    <i className="icon icon-arrow-right" />
                </a>
            </div>
        </div>
    );
};

export const ThankyouSubFooterHeader: React.FC<ThankyouProps> = ({
    title,
    view_all_cta_link,
}) => {
    return (
        <div tabIndex={0} className="thankyou__subfooter__header">
            <h2 tabIndex={0}>{title}</h2>
            <a href={view_all_cta_link} target="_blank" rel="noreferrer">
                <span>View all</span>
                <i className="icon icon-arrow-right" />
            </a>
        </div>
    );
};
