import type React from "react";
import img from "@assets/img/illustration-landing.svg";

interface Props {
    eyebrow?: string;
    title?: string;
    description?: string;
}

const HeroLanding: React.FC<Props> = ({ eyebrow, title, description }) => {
    return (
        <div className="landing__hero">
            <div className="landing__hero__inner">
                <div tabIndex={0} className="landing__hero__text__container">
                    <strong>{eyebrow ? eyebrow : "Welcome to the"}</strong>
                    <h1>{title ? title : "Child Safe Self-Assessment"}</h1>
                    <p>
                        {description
                            ? description
                            : " A free online resource to support organisations improve their child safe practice."}
                    </p>
                </div>
                <img src={img} alt="Child safe self assessment illustration" />
            </div>
        </div>
    );
};

export default HeroLanding;
