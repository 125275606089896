import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@js/store/hooks";

interface ModalState {
    showModal: boolean;
    type: "save" | "continue" | "";
}

const initialState: ModalState = {
    showModal: false,
    type: "",
};

export const sliceModal = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setSection: (state, action) => {
            state.showModal = !state.showModal;
            state.type = action.payload;
        },
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useModal = () => {
    const dispatch = useAppDispatch();

    const showModal = useAppSelector(state => state.modal.showModal);
    const type = useAppSelector(state => state.modal.type);
    const setShowModal = (type: string) =>
        dispatch(sliceModal.actions.setSection(type));

    return { showModal, type, setShowModal };
};

export default sliceModal.reducer;
