import { configureStore } from "@reduxjs/toolkit";
import formReducer from "@js/slices/sliceForm";
import inviteReducer from "@js/slices/sliceInvite";
import modalReducer from "@js/slices/sliceModal";
import storyBlokReducer from "@js/slices/sliceStoryBlok";
import weglotReducer from "@js/slices/sliceWeglot";

export const store = configureStore({
    reducer: {
        form: formReducer,
        invite: inviteReducer,
        modal: modalReducer,
        storyblok: storyBlokReducer,
        weglot: weglotReducer,
    },
    middleware: getDefaultMiddleware =>
        //TODO: remove serializableCheck
        getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
