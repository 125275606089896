import type React from "react";

interface ButtonLinkProps {
    url: string;
    anchorText: string;
    iconClass?: string;
    iconLeft?: boolean;
    outlineButton: boolean;
    className?: string;
}

interface ButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    anchorText: string;
    iconClass?: string;
    iconLeft?: boolean;
    outlineButton: boolean;
    disabled?: boolean;
    className?: string;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
    url,
    anchorText,
    iconClass,
    iconLeft,
    outlineButton,
    className = "",
}) => {
    return (
        <a
            className={
                outlineButton
                    ? "button button__outline " + className
                    : "button " + className
            }
            href={url}
        >
            {iconClass && iconLeft && (
                <i
                    style={{ marginRight: "0.5rem" }}
                    className={"icon " + iconClass}
                />
            )}
            {anchorText}
            {iconClass && !iconLeft && (
                <i
                    style={{ marginLeft: "0.5rem" }}
                    className={"icon " + iconClass}
                />
            )}
        </a>
    );
};

export const Button: React.FC<ButtonProps> = ({
    onClick,
    anchorText,
    iconClass,
    iconLeft,
    outlineButton,
    disabled,
    className,
}) => {
    return (
        <button
            disabled={disabled ? true : false}
            className={
                outlineButton
                    ? "button button__outline " + className
                    : "button " + className
            }
            onClick={onClick}
        >
            {iconClass && iconLeft && (
                <span className="button__abs-icon-left">
                    <i className={"icon " + iconClass} />
                </span>
            )}

            {anchorText}
            {iconClass && !iconLeft && (
                <span className="button__abs-icon-right">
                    <i className={"icon " + iconClass} />
                </span>
            )}
        </button>
    );
};
