import type React from "react";
import { useEffect } from "react";
import { scrollTo } from "scroll-js";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { ReturnMarkup } from "@js/molecules/StoryBlokComponents";
import { useStoryBlok } from "@js/slices/sliceStoryBlok";

const Faq: React.FC = () => {
    const { jsonData } = useStoryBlok();
    const faqData = jsonData?.story?.content as {
        body: FAQData[];
        title: string;
    };

    useEffect(() => {
        scrollTo(window, { top: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        const contentElements = document
            .getElementById("faq-container")
            ?.querySelectorAll("p");

        if (contentElements) {
            for (let i = 0; i < contentElements.length; i++) {
                contentElements[i].setAttribute("tabindex", "0");
            }
        }
    }, [jsonData]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children&apos;s Guardian | Self Assessment |
                    Frequently Asked Questions
                </title>
            </Helmet>

            <div id="faq-container" className="faq">
                <div className="faq__inner">
                    <h1 tabIndex={0}>{faqData.title}</h1>
                    {faqData.body.map(faq => {
                        return (
                            <div key={faq._uid}>
                                <h3 tabIndex={0}>{faq.title}</h3>
                                <ul className="faq__accordion">
                                    {faq.body.map(
                                        (faqItem: FAQItem, i: number) => {
                                            return (
                                                <AccordionItem
                                                    key={faqItem._uid + i}
                                                    question={faqItem.title}
                                                    answer={faqItem.body}
                                                />
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        );
                    })}
                    <div className="faq__inner-help">
                        <h3 tabIndex={0}>Still need help?</h3>
                        <p tabIndex={0}>
                            Please email{" "}
                            <a href="mailto:childsafesupport@ocg.nsw.gov.au">
                            childsafesupport@ocg.nsw.gov.au
                            </a>{" "}
                            with any other questions you have.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;

interface AccordionItemProps {
    question: string;
    answer: FAQAccordionContent;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ question, answer }) => {
    const [active, setActive] = useState(false);
    return (
        <li className="faq__accordion-item">
            <button onClick={() => setActive(!active)}>
                <span tabIndex={0} className="faq__accordion-item-question">
                    {question}
                </span>
                <span className="faq__accordion-item-toggle">
                    {active ? (
                        <i className="icon icon-minus" />
                    ) : (
                        <i className="icon icon-plus" />
                    )}
                </span>
            </button>
            <div className={`faq__accordion-item-answer ${active && "open"}`}>
                <ReturnMarkup content={answer} tooltip={[]} />
            </div>
        </li>
    );
};
