import type React from "react";
import img from "@assets/img/illustration-landing.svg";

interface Props {
    message?: string;
}

const HeroErrorBoundary: React.FC<Props> = ({ message }) => {
    return (
        <div className="landing__hero">
            <div className="landing__hero__inner">
                <div className="landing__hero__text__container-error">
                    <h1 tabIndex={0}>
                        {!message ? `Oops. We can't find that page.` : message}
                    </h1>
                </div>
                <img src={img} alt="Child safe self assessment illustration" />
            </div>
        </div>
    );
};

export default HeroErrorBoundary;
