import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@js/store/hooks";
import { api } from "@js/utilities";

interface StoryBlokState {
    path: string;
    jsonData: { [path: string]: Record<string, any> | null };
}

const initialState: StoryBlokState = {
    path: "",
    jsonData: {},
};

export const sliceStoryBlok = createSlice({
    name: "storyblok",
    initialState,
    reducers: {
        setPath: (state, action) => {
            state.path = action.payload;
        },
        setJsonData: (state, action) => {
            state.jsonData = { ...state.jsonData, ...action.payload };
        },
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useStoryBlok = () => {
    const dispatch = useAppDispatch();
    const jsonData = useAppSelector(
        state => state.storyblok.jsonData[state.storyblok.path]
    );
    const storyblokState = useAppSelector(state => state.storyblok);
    const storyblokPath = useAppSelector(state => state.storyblok.path);

    const fetchContent = (path: string) => {
        dispatch(sliceStoryBlok.actions.setPath(path));
        if (!storyblokState.jsonData[path]) {
            api.get(`/api/v1/content?path=${path}`).then(response =>
                dispatch(
                    sliceStoryBlok.actions.setJsonData({
                        [path]: response.data,
                    })
                )
            );
        }
    };

    return { fetchContent, jsonData, storyblokPath };
};

export default sliceStoryBlok.reducer;
