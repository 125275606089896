import type React from "react";
import { useState, Fragment, useEffect } from "react";
import ReactSlider from "react-slider";
import { useForm } from "@js/slices/sliceForm";
import { emailRegex, validatePhone } from "@js/utilities";

interface Option {
    label: string;
    score: string;
    value: string;
    behaviour: string;
    _uid: string;
}

interface RadioInputProps {
    options?: Option[];
    questionId: string;
    dependent?: string;
    showDependent?: boolean;
    submitError?: Error;
    disabled?: boolean;
}

interface CheckboxInputProps {
    options?: Option[];
    questionId: string;
    submitError?: Error;
    disabled?: boolean;
}

interface SelectInputProps {
    options?: Option[];
    questionId: string;
    submitError?: Error;
    disabled?: boolean;
}

interface SliderInputProps {
    labelForMinScore?: string;
    labelForMaxScore?: string;
    questionId: string;
    answerId: string;
    submitError?: Error;
    disabled?: boolean;
}

interface TextAreaProps {
    option: {
        _uid: string;
    };
    questionId: string;
    getVal?: any;
    disabled?: boolean;
}

interface TextInputProps {
    questionId: string;
    answerId: string;
    label?: string;
    placeholder?: string;
    subText?: string;
    superText?: string;
    required: boolean;
    disabled?: boolean;
    type: string;
    abnLookupAction?: boolean;
    numberOnly?: boolean;
    submitError?: Error;
    businessNameSubmitError?: Error;
}

interface Error {
    inputId: string;
    error: string;
}

export const RadioInput: React.FC<RadioInputProps> = ({
    options,
    questionId,
    dependent,
    submitError,
    disabled,
}) => {
    const { setFormData, formData } = useForm();
    const [textAreaVal, setTextAreaVal] = useState<string>("");

    const hasAnswer = formData.some(answer => answer.questionId === questionId);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, id, type } = e.target;
        let dependentId = "";
        if (dependent) {
            dependentId = dependent.split("|")[0];
        }
        setFormData({ type, answerId: id, value, questionId, dependentId });
    };

    const getVal = (val: string) => {
        setTextAreaVal(val);
        return val;
    };

    return (
        <>
            {submitError && !hasAnswer && (
                <div className="on-change-valid">{submitError.error}</div>
            )}
            <div id={questionId} className="input input__radio">
                {options &&
                    options.map(option => {
                        const isChecked = formData.some(
                            answer => answer.answerId === option._uid
                        );
                        const hasFreeText =
                            option.behaviour === "free-text-required";
                        const showFreeText = isChecked && hasFreeText;
                        const showFreeTextError = showFreeText && submitError;
                        return (
                            <Fragment key={option._uid}>
                                <div
                                    className="input__radio-element"
                                    onClick={() => {
                                        document
                                            .getElementById(option._uid)
                                            ?.click();
                                    }}
                                >
                                    <input
                                        required={true}
                                        name={option._uid}
                                        id={option._uid}
                                        type="radio"
                                        value={option.value}
                                        checked={isChecked}
                                        disabled={disabled}
                                        onChange={e => handleChange(e)}
                                    />
                                    <label htmlFor={option._uid}>
                                        {option.label}
                                    </label>
                                </div>
                                {showFreeText && (
                                    <>
                                        {showFreeTextError ? (
                                            <div className="on-change-valid on-change-valid-textarea">
                                                Please specify your response
                                                below
                                            </div>
                                        ) : null}
                                        <TextAreaInput
                                            option={option}
                                            questionId={questionId}
                                            getVal={getVal}
                                            disabled={disabled}
                                        />
                                    </>
                                )}
                            </Fragment>
                        );
                    })}
            </div>
        </>
    );
};

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
    options,
    questionId,
    submitError,
    disabled,
}) => {
    const { setFormData, formData } = useForm();
    const [textAreaVal, setTextAreaVal] = useState<string>("");

    const hasAnswer = formData.some(answer => answer.questionId === questionId);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, id } = e.target;
        setFormData({
            type: "checkbox",
            answerId: id,
            value,
            questionId,
        });
    };

    const getVal = (val: string) => {
        setTextAreaVal(val);
        return val;
    };

    return (
        <>
            {submitError && !hasAnswer && (
                <div className="on-change-valid">{submitError.error}</div>
            )}
            <div id={questionId} className="input input__checkbox">
                {options &&
                    options.map(option => {
                        const isChecked = formData.some(
                            answer => answer.answerId === option._uid
                        );
                        const hasFreeText =
                            option.behaviour === "free-text-required";
                        const showFreeText = isChecked && hasFreeText;
                        const showFreeTextError = showFreeText && submitError;

                        return (
                            <Fragment key={option._uid}>
                                <div
                                    style={{
                                        borderBottom: showFreeText
                                            ? "none"
                                            : "",
                                    }}
                                    className="input__checkbox-element"
                                    onClick={e => {
                                        e.stopPropagation();
                                        document
                                            .getElementById(option._uid)
                                            ?.click();
                                    }}
                                    key={option._uid}
                                >
                                    <input
                                        required={true}
                                        name={option._uid}
                                        id={option._uid}
                                        type="checkbox"
                                        value={option.value}
                                        disabled={disabled}
                                        checked={isChecked}
                                        onClick={(e: any) =>
                                            e.stopPropagation()
                                        }
                                        onChange={e => handleChange(e)}
                                    />
                                    <label
                                        onClick={(e: any) =>
                                            e.stopPropagation()
                                        }
                                        htmlFor={option._uid}
                                    >
                                        {option.label}
                                    </label>
                                </div>
                                {showFreeText && (
                                    <>
                                        {showFreeTextError ? (
                                            <div className="on-change-valid on-change-valid-textarea">
                                                Please specify your response
                                                below
                                            </div>
                                        ) : null}
                                        <TextAreaInput
                                            disabled={disabled}
                                            option={option}
                                            questionId={questionId}
                                            getVal={getVal}
                                        />
                                    </>
                                )}
                            </Fragment>
                        );
                    })}
            </div>
        </>
    );
};

const TextAreaInput: React.FC<TextAreaProps> = ({
    option,
    questionId,
    getVal,
    disabled,
}) => {
    const { setFormData, formData } = useForm();
    const [show, setShow] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        const found = formData.find(
            question => question.answerId === option._uid
        );
        if (found) {
            setShow(true);
            if (found.value === "other") {
                getVal(message);
                setMessage(message);
                if (message) {
                    setFormData({ ...found, type: "textarea", value: message });
                }
            } else {
                getVal(found.value.toString());
                setMessage(found.value.toString());
            }
        } else {
            setShow(false);
            getVal(message);
            setMessage(message);
        }
    }, [formData]);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value, type, id } = e.target;
        const answerId = id.split("|")[0];
        setFormData({ type, answerId, value, questionId });
    };

    return (
        <>
            {show && (
                <>
                    <div className="input input__textarea">
                        <textarea
                            required={true}
                            id={`${option._uid}|textarea`}
                            placeholder="Enter your response"
                            onChange={e => handleChange(e)}
                            maxLength={1000}
                            value={message}
                            disabled={disabled}
                        ></textarea>
                    </div>
                    <small className="input__textarea-character-count">
                        {message.length} / 1000 characters
                    </small>
                </>
            )}
        </>
    );
};

export const SelectInput: React.FC<SelectInputProps> = ({
    options,
    questionId,
    submitError,
    disabled,
}) => {
    const { setFormData, formData } = useForm();
    const [val, setVal] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value, id, type } = e.target;
        setVal(value);
        setFormData({ type, answerId: id, value, questionId });
    };

    const selectVal = () => {
        let val: string | string[] = "";
        formData.find(question => {
            if (question.questionId === questionId) {
                val = question.value;
            }
        });
        return val;
    };

    return (
        <>
            {submitError && val === "" && selectVal() === "" && (
                <div className="on-change-valid">{submitError.error}</div>
            )}
            <div className="input input__select">
                <div className="input__inner">
                    <div className="input__select-input-wrapper">
                        <select
                            required={true}
                            id={questionId}
                            name={questionId}
                            onChange={e => handleChange(e)}
                            defaultValue={selectVal() !== "" ? selectVal() : ""}
                            disabled={disabled}
                        >
                            <option
                                key="default-select"
                                id="default-select"
                                value=""
                            >
                                Please select an option
                            </option>
                            {options &&
                                options.map(option => {
                                    return (
                                        <option
                                            key={option._uid}
                                            id={option._uid}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
};

const SliderInput: React.FC<SliderInputProps> = ({
    labelForMinScore,
    labelForMaxScore,
    questionId,
    answerId,
    submitError,
    disabled,
}) => {
    const { setFormData, formData } = useForm();
    const [change, setChange] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const [touching, setTouching] = useState<boolean>(false);

    const hasVal = formData.find(
        question => question.questionId === questionId
    );

    const handleChange = (val: number) => {
        const value = val.toString();
        setChange(true);
        setFormData({
            type: "slider",
            answerId,
            value,
            questionId,
        });
    };

    const sliderVal = () => {
        let val: string | string[] | undefined = undefined;
        formData.find(question => {
            if (question.answerId === answerId) {
                val = question.value;
            }
        });
        if (val !== undefined) {
            return parseInt(val);
        }
    };
    return (
        <>
            {submitError && !change && !touched && !hasVal && (
                <div className="on-change-valid">{submitError.error}</div>
            )}
            <div id={questionId}>
                <ReactSlider
                    disabled={disabled}
                    className="input__slider"
                    marks={1}
                    markClassName="input__slider-mark"
                    min={0}
                    max={10}
                    defaultValue={sliderVal() !== undefined ? sliderVal() : 0}
                    thumbClassName="input__slider-thumb"
                    trackClassName="input__slider-track"
                    onAfterChange={(val: number) => handleChange(val)}
                    renderThumb={(props, state) => (
                        <div {...props}>
                            {touching && (
                                <div className="input__slider-thumb-tooltip">
                                    {state.value}
                                </div>
                            )}
                            <div
                                onTouchStart={() => {
                                    setTouching(true);
                                    setTouched(true);
                                }}
                                onTouchEnd={() => {
                                    setTouching(false);
                                    setTouched(true);
                                }}
                                onMouseDown={() => setTouched(true)}
                                onMouseUp={() => setTouched(true)}
                                className={
                                    touched ||
                                    sliderVal() !== undefined ||
                                    state.value > 0
                                        ? "input__slider-thumb-fill"
                                        : ""
                                }
                            >
                                {state.value}
                            </div>
                        </div>
                    )}
                    renderMark={props => (
                        <div {...props}>
                            <span className="input__slider-mark-number">
                                {props.key}
                            </span>
                        </div>
                    )}
                    renderTrack={(props, state) => (
                        <div {...props}>
                            <div
                                className={
                                    sliderVal() !== undefined || state.value > 0
                                        ? "z"
                                        : ""
                                }
                            ></div>
                        </div>
                    )}
                />
                <div className="input__slider-labels">
                    <span>{labelForMinScore}</span>
                    <span>{labelForMaxScore}</span>
                </div>
            </div>
        </>
    );
};

export const TextInput: React.FC<TextInputProps> = ({
    questionId,
    answerId,
    label,
    placeholder,
    superText,
    subText,
    required,
    disabled,
    type,
    abnLookupAction,
    numberOnly,
    submitError,
    businessNameSubmitError,
}) => {
    const { formData, setFormData, abnLookup } = useForm();
    const [businessNames, setBusinessNames] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const numberRegex = /^[0-9 ]*$/;
    const numberWithoutSpacesRegex = /^[0-9]*$/;

    const setBusinessName = (name: string) => {
        setFormData({
            type: "text",
            questionId: "business-name-q",
            answerId: "business-name-a",
            value: name,
        });
        setBusinessNames([]);
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        if (abnLookupAction) {
            const value = e.clipboardData.getData("Text").replace(/\s+/g, "");
            if (!numberWithoutSpacesRegex.test(value)) {
                return;
            }
            setFormData({
                type,
                answerId,
                value,
                questionId,
            });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, type } = e.target;
        const removeSpaces = value.replace(/\s+/g, "");
        e.preventDefault();

        const validateEmail = () => {
            if (!emailRegex.test(value)) {
                setError("Please enter a valid email address");
            } else setError(null);
        };

        const validateAbn = () => {
            if (removeSpaces.length !== 11) {
                setError("ABN must be 11 characters");
            } else setError(null);
        };

        if (numberOnly) {
            if (!numberRegex.test(value)) {
                return;
            } else {
                setFormData({
                    type,
                    answerId,
                    value: removeSpaces,
                    questionId,
                });
            }
        } else if (type === "email") {
            setFormData({
                type,
                answerId,
                value,
                questionId,
            });
            validateEmail();
        } else if (type === "tel") {
            if (!numberRegex.test(value)) {
                return;
            } else {
                setFormData({
                    type,
                    answerId,
                    value: removeSpaces,
                    questionId,
                });
            }
        } else if (abnLookupAction) {
            if (!numberWithoutSpacesRegex.test(value)) {
                return;
            }
            setFormData({
                type,
                answerId,
                value,
                questionId,
            });
            validateAbn();
        } else {
            setFormData({ type, answerId, value, questionId });
        }
    };

    const inputVal = () => {
        let message: string | string[] = "";
        formData.find(question => {
            if (question.answerId === answerId) {
                message = question.value;
            }
        });
        return message;
    };

    const returnMaxLength = () => {
        if (type === "tel") {
            return 12;
        }
        if (abnLookupAction) {
            return 11;
        }
        return undefined;
    };

    const searchAbn = (e: any) => {
        e?.preventDefault();
        abnLookup()
            .then(setBusinessNames)
            .then(() => setError(""))
            .catch(error => {
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                }
            });
    };

    return (
        <>
            <div className="input input__text">
                <div>
                    <label htmlFor={answerId}>
                        {label}
                        {required && questionId !== "abn-q" && "*"}
                    </label>
                    {superText && (
                        <p className="sat__form-item-super">{superText}</p>
                    )}
                    <div>
                        {submitError &&
                            inputVal().length < 1 &&
                            submitError?.error === "Please enter a value" && (
                                <div
                                    id={`submit-error-${answerId}`}
                                    className="on-change-valid-sm"
                                >
                                    {submitError.error}
                                </div>
                            )}
                        {submitError &&
                            !validatePhone(inputVal()) &&
                            submitError?.error ===
                                "Phone number must be between 8 and 12 characters" && (
                                <div
                                    id={`submit-error-${answerId}`}
                                    className="on-change-valid-sm"
                                >
                                    {submitError.error}
                                </div>
                            )}
                        {type === "email" &&
                            submitError?.error ===
                                "Please enter a valid email address" &&
                            !emailRegex.test(inputVal()) && (
                                <div
                                    id={`submit-error-${answerId}`}
                                    className="on-change-valid-sm"
                                >
                                    {submitError.error}
                                </div>
                            )}
                        {error &&
                            answerId === "abn-a" &&
                            !document.getElementById("submit-error-abn-a") && (
                                <div
                                    id="on-change-valid"
                                    className="on-change-valid-sm"
                                >
                                    {error}
                                </div>
                            )}
                        <div className="input__text-wrapper">
                            <input
                                name={answerId}
                                type={type}
                                maxLength={returnMaxLength()}
                                required={required}
                                disabled={disabled}
                                id={answerId}
                                onChange={e => handleChange(e)}
                                placeholder={placeholder}
                                value={inputVal()}
                                onPaste={handlePaste}
                                onKeyDown={e => {
                                    if (e.key === "Enter" && abnLookupAction) {
                                        searchAbn(e);
                                    }
                                }}
                            />
                            {abnLookupAction && (
                                <button
                                    className="input__text-button"
                                    disabled={
                                        inputVal().length < 11 ? true : false
                                    }
                                    onClick={searchAbn}
                                >
                                    Search
                                </button>
                            )}
                        </div>
                    </div>

                    {subText && <p className="sat__form-item-sub">{subText}</p>}
                </div>
            </div>
            {businessNames?.length > 0 && (
                <>
                    <div
                        className={`input__text__abn-lookup-results ${
                            businessNameSubmitError &&
                            "input__text__abn-lookup-results--error"
                        }`}
                    >
                        <strong tabIndex={0}>
                            Please select business / entity name
                        </strong>
                        {businessNames.map((name, key) => (
                            <p
                                tabIndex={0}
                                key={`business_name_${key}`}
                                onKeyDown={e => {
                                    if (e.key === "Enter")
                                        setBusinessName(name);
                                }}
                                onClick={() => {
                                    setBusinessName(name);
                                }}
                            >
                                {name.toLocaleLowerCase()}
                            </p>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export const returnInput = (
    answer: Answer,
    _uid: string,
    dependent?: string,
    submitError?: Error,
    disabled?: boolean
): React.ReactElement | undefined => {
    switch (answer.type) {
        case "radio":
            return (
                <RadioInput
                    submitError={submitError}
                    options={answer.options}
                    questionId={_uid}
                    dependent={dependent}
                    disabled={disabled}
                />
            );
        case "checkbox":
            return (
                <CheckboxInput
                    submitError={submitError}
                    options={answer.options}
                    questionId={_uid}
                    disabled={disabled}
                />
            );
        case "dropdown":
            return (
                <SelectInput
                    submitError={submitError}
                    options={answer.options}
                    questionId={_uid}
                    disabled={disabled}
                />
            );
        case "slider":
            return (
                <SliderInput
                    submitError={submitError}
                    labelForMinScore={answer.labelForMinScore}
                    labelForMaxScore={answer.labelForMaxScore}
                    questionId={_uid}
                    answerId={answer._uid}
                    disabled={disabled}
                />
            );
    }
};
