import { createSlice, current } from "@reduxjs/toolkit";
import {
    createEntryIdMapping,
    matchAboutYou,
    matchAnswers,
    saveEntryIdMapping,
} from "@js/store/form";
import { useAppDispatch, useAppSelector } from "@js/store/hooks";
import { api } from "@js/utilities";

interface Answer {
    type?: string;
    answerId?: string;
    value: string | string[];
    questionId: string;
    dependentId?: string;
}

interface ApiAnswer {
    questionId?: string;
    answer?: string;
    answerAdditional?: null | string;
}

interface Entry {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string | number;
    role:
        | string
        | "employee-or-volunteer"
        | "manager-or-supervisor-or-team-leader"
        | "human-resources-team"
        | "executive-or-board-member";
    consentAuthorised: string | boolean;
    abn?: string;
    entityName?: string;
    addressLine1?: string;
    addressUnit?: string;
    addressSuburb?: string;
    addressState?: string;
    addressPostcode?: string;
    locationOrBusinessUnit?: string;
}

interface FormState {
    completedSections: number[];
    section: number;
    token: string;
    entryId: string;
    formData: Answer[];
    apiError: Error | null;
    savedCaptchaToken: string | null;
    isSubmittingForm: boolean;
}

const initialState: FormState = {
    completedSections: [],
    section: 0,
    token: "",
    entryId: "",
    formData: [],
    apiError: null,
    savedCaptchaToken: null,
    isSubmittingForm: false,
};

export const sliceForm = createSlice({
    name: "form",
    initialState,
    reducers: {
        setCompletedSections: (state, action) => {
            state.completedSections = [
                ...state.completedSections,
                action.payload,
            ];
        },
        setSection: (state, action) => {
            state.section = action.payload;
        },
        setEntry: (state, action) => {
            state.entryId = action.payload.entryId;
            if (action.payload.token) {
                state.token = action.payload.token;
            }
        },
        setApiError: (state, action) => {
            let message;
            if (action.payload?.response) {
                message =
                    action.payload?.response?.data?.message ||
                    "Unspecified server error occured.";
            }
            state.apiError = message ? new Error(message) : null;
        },
        setCaptcha: (state, action) => {
            state.savedCaptchaToken = action.payload;
        },
        setFormData: (state, action) => {
            const { type, questionId, answerId, value } = action.payload;
            // checking if question has been answered
            const questionHasAnswer = state.formData.find(
                question => question.questionId === questionId
            );

            const updateAnswersSelect = () => {
                if (questionHasAnswer) {
                    // find question in array and replace the answer
                    const index = state.formData
                        .map(question => question.questionId)
                        .indexOf(questionId);
                    const updatedAnswers = Object.assign(state.formData, {
                        [index]: action.payload,
                    });
                    state.formData = updatedAnswers;
                } else {
                    state.formData = [...state.formData, action.payload];
                }
            };

            const updateAnswersRadio = () => {
                if (questionHasAnswer) {
                    // find question in array and replace the answer
                    const index = state.formData
                        .map(question => question.questionId)
                        .indexOf(questionId);

                    // check formData for dependentId, if exist we remove it.
                    const dependentIndex = state.formData.findIndex(
                        question => {
                            if (question.dependentId) {
                                return question.dependentId !== "";
                            }
                        }
                    );

                    if (
                        dependentIndex > -1 &&
                        action.payload.questionId ===
                            state.formData[dependentIndex].dependentId
                    ) {
                        const removeDependentAnswer = current(
                            state.formData
                        ).filter(question => {
                            return (
                                question.dependentId === "" ||
                                question.dependentId === undefined
                            );
                        });
                        const updateDependentQuesiont = Object.assign(
                            removeDependentAnswer,
                            {
                                [index]: action.payload,
                            }
                        );
                        state.formData = updateDependentQuesiont;
                    } else {
                        // find existing answer and update it
                        const updatedAnswers = Object.assign(state.formData, {
                            [index]: action.payload,
                        });
                        state.formData = updatedAnswers;
                    }
                } else {
                    state.formData = [...state.formData, action.payload];
                }
            };

            const updateAnswersCheckbox = () => {
                const answerIsChecked = () => {
                    return state.formData
                        .map(question => question.answerId)
                        .indexOf(answerId);
                };

                if (questionHasAnswer) {
                    const answerCheckIndex = answerIsChecked(); // getting index of checked answer
                    if (answerCheckIndex > -1) {
                        // removing checked answer from formData array
                        const removeAnswer = state.formData.filter((el, i) => {
                            return i !== answerCheckIndex;
                        });
                        state.formData = removeAnswer;
                        return;
                    }
                    // remove all checked answers if value is none
                    if (value === "none") {
                        const removeAllCheckedValues = (item: any) => {
                            return (
                                (item.type === "checkbox" &&
                                    item.value === "none") ||
                                (item.type !== "checkbox" &&
                                    item.type !== "textarea") ||
                                item.questionId !== questionId
                            );
                        };
                        const updateAnswers = state.formData.filter(
                            removeAllCheckedValues
                        );
                        state.formData = [...updateAnswers, action.payload];
                        return;
                    }
                    // remove all checked answers if value is unsure
                    if (value === "unsure") {
                        const removeAllCheckedValues = (item: any) => {
                            return (
                                (item.type === "checkbox" &&
                                    item.value === "unsure") ||
                                (item.type !== "checkbox" &&
                                    item.type !== "textarea") ||
                                item.questionId !== questionId
                            );
                        };
                        const updateAnswers = state.formData.filter(
                            removeAllCheckedValues
                        );
                        state.formData = [...updateAnswers, action.payload];
                        return;
                    }

                    // remove all checkbox answers if value is not-applicable
                    if (value === "not-applicable") {
                        const removeAllCheckedValues = (item: any) => {
                            return (
                                (item.type === "checkbox" &&
                                    item.value === "not-applicable") ||
                                (item.type !== "checkbox" &&
                                    item.type !== "textarea") ||
                                item.questionId !== questionId
                            );
                        };
                        const updateAnswers = state.formData.filter(
                            removeAllCheckedValues
                        );
                        state.formData = [...updateAnswers, action.payload];
                        return;
                    }

                    // need to check if none or unsure or non applicable has been checked - these are removed if they exist in array
                    const checkForUnsureOrNoneOrNotapplicable = (item: any) => {
                        return (
                            (item.type === "checkbox" &&
                                item.value === "none") ||
                            (item.type === "checkbox" &&
                                item.value === "unsure") ||
                            (item.type === "checkbox" &&
                                item.value === "not-applicable")
                        );
                    };
                    // if value selected is not unsure or none or non applicable we need to check for and remove unsure or none or non applicable from state
                    const removeUnsureOrNoneOrNotapplicable = (arr: any[]) => {
                        const newArray = [];
                        for (let i = 0; i < arr.length; i++) {
                            const notNone =
                                arr[i].type === "checkbox" &&
                                arr[i].value !== "none";
                            const notUnsure =
                                arr[i].type === "checkbox" &&
                                arr[i].value !== "unsure";

                            const notNonApplicable =
                                arr[i].type === "checkbox" &&
                                arr[i].value !== "not-applicable";

                            const unsureInDifferentQuestion =
                                arr[i].type === "checkbox" &&
                                arr[i].value === "unsure" &&
                                arr[i].questionId !== action.payload.questionId;
                            const noneInDifferentQuestion =
                                arr[i].type === "checkbox" &&
                                arr[i].value === "none" &&
                                arr[i].questionId !== action.payload.questionId;
                            const nonApplicableInDifferentQuestion =
                                arr[i].type === "checkbox" &&
                                arr[i].value === "not-applicable" &&
                                arr[i].questionId !== action.payload.questionId;

                            const notCheckBoxType = arr[i].type !== "checkbox";
                            if (
                                (notNone && notUnsure && notNonApplicable) ||
                                notCheckBoxType ||
                                unsureInDifferentQuestion ||
                                noneInDifferentQuestion ||
                                nonApplicableInDifferentQuestion
                            ) {
                                newArray.push(current(arr[i]));
                            }
                        }
                        state.formData = [...newArray, action.payload];
                    };

                    const unsureOrNoneExists = state.formData.findIndex(
                        checkForUnsureOrNoneOrNotapplicable
                    );

                    if (unsureOrNoneExists > -1) {
                        if (
                            action.payload !== "unsure" ||
                            action.payload !== "none" ||
                            action.payload !== "non-applicable"
                        ) {
                            removeUnsureOrNoneOrNotapplicable(state.formData);
                        }
                        return;
                    }

                    state.formData = [...state.formData, action.payload];
                } else {
                    state.formData = [...state.formData, action.payload];
                }
            };

            const updateAnswersSliderText = () => {
                const answerExists = state.formData
                    .map(question => question.answerId)
                    .indexOf(answerId);
                if (answerExists > -1) {
                    const updatedAnswers = Object.assign(state.formData, {
                        [answerExists]: action.payload,
                    });
                    state.formData = updatedAnswers;
                    return;
                }
                state.formData = [...state.formData, action.payload];
            };

            switch (type) {
                case "radio":
                    updateAnswersRadio();
                    break;
                case "select-one":
                    updateAnswersSelect();
                    break;
                case "checkbox":
                    updateAnswersCheckbox();
                    break;
                case "textarea":
                    updateAnswersSliderText();
                    break;
                case "slider":
                    updateAnswersSliderText();
                    break;
                case "text":
                    updateAnswersSliderText();
                    break;
                case "email":
                    updateAnswersSliderText();
                    break;
                case "tel":
                    updateAnswersSliderText();
                    break;
                default:
                    break;
            }
        },
        resetFormData: state => {
            state.formData = [];
        },
        setIsSubmittingForm: (state, action) => {
            state.isSubmittingForm = action.payload;
        },
    },
});

const mapEntryForm = (formData: Answer[], mappedEntry: Entry) => {
    return formData.reduce((reducer, item) => {
        const mappedKey = Object.entries(mappedEntry).reduce(
            (newKey, [key, value]) => {
                if (value === item.questionId) {
                    newKey = key;
                }
                return newKey;
            },
            ""
        );

        if (mappedKey) {
            reducer[mappedKey] = item.value === "true" ? true : item.value;
        }
        return reducer;
    }, {} as any);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useForm = () => {
    const dispatch = useAppDispatch();

    const section = useAppSelector(state => state.form.section);
    const completedSections = useAppSelector(
        state => state.form.completedSections
    );
    const formData = useAppSelector(state => state.form.formData);
    const token = useAppSelector(state => state.form.token);
    const entryId = useAppSelector(state => state.form.entryId);
    const saveStep = useAppSelector(
        state =>
            state.storyblok?.jsonData?.["sat-form"]?.story?.content?.sections?.[
                section
            ]?.pageSlug
    );
    const inviterData = useAppSelector(
        state => state.invite?.inviterData as any
    );
    const apiError = useAppSelector(state => state.form.apiError);
    const sections = useAppSelector(
        state => state.storyblok.jsonData?.["sat-form"]?.story?.content.sections
    );
    const answers = useAppSelector(state => {
        const uuidRegex =
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return state.form.formData
            .filter(answer => uuidRegex.test(answer.questionId))
            .map(answer => {
                const hasOther =
                    state.form.formData.filter(
                        i => i.questionId === answer?.questionId
                    ).length >= 1;
                return {
                    questionId: answer.questionId,
                    answer:
                        answer.type === "textarea"
                            ? hasOther
                                ? "other"
                                : ""
                            : answer.value,
                    answerAdditional:
                        answer.type === "textarea" ? answer.value : null,
                } as ApiAnswer;
            })
            .reduce((reducer, value) => {
                const multiSelectIndex = reducer.findIndex(
                    v => v.questionId === value.questionId
                );
                if (multiSelectIndex > -1) {
                    reducer[multiSelectIndex] = {
                        ...reducer[multiSelectIndex],
                        answerAdditional:
                            value.answerAdditional ||
                            reducer[multiSelectIndex].answerAdditional,
                        answer: `${reducer[multiSelectIndex].answer}|${value.answer}`,
                    };
                } else {
                    reducer = reducer.concat(value);
                }
                return reducer;
            }, [] as ApiAnswer[]);
    });
    const savedCaptchaToken = useAppSelector(
        state => state.form.savedCaptchaToken
    );

    const setSection = (sectionNumber: number) =>
        dispatch(sliceForm.actions.setSection(sectionNumber));
    const setCompletedSections = (sectionNumber: number) => {
        dispatch(sliceForm.actions.setCompletedSections(sectionNumber));
    };
    const setFormData = (answer: Answer) => {
        dispatch(sliceForm.actions.setFormData(answer));
    };
    const resetFormData = () => {
        dispatch(sliceForm.actions.resetFormData());
    };
    const setApiError = (error?: Error) => {
        dispatch(sliceForm.actions.setApiError(error));
    };
    const setCaptcha = (captchaToken: string) => {
        dispatch(sliceForm.actions.setCaptcha(captchaToken));
    };

    const createEntry = async (recaptchaRef: any) => {
        let captchaToken: string = "";
        if (!savedCaptchaToken) {
            captchaToken = await recaptchaRef?.current?.executeAsync();
            setCaptcha(captchaToken);
        }
        return await api
            .post(
                `/api/v1/entry?captchaResponse=${
                    !savedCaptchaToken ? captchaToken : savedCaptchaToken
                }`,
                mapEntryForm(formData, createEntryIdMapping)
            )
            .then(response => {
                dispatch(
                    sliceForm.actions.setEntry({
                        entryId: response.data.entryId,
                        token: response.data.token,
                    })
                );
                return { data: response.data, error: null };
            })
            .catch(error => {
                setApiError(error);
                return { error, data: null };
            });
    };

    const abnLookup = () => {
        let abn = formData.find(a => a.questionId === "abn-q")?.value;
        if (typeof abn === "string") {
            abn = abn.replace(/\s+/g, "");
        }
        return api
            .get(`/api/v1/entry/abn-lookup?abn=${abn}`, {
                headers: { JwtAuthorisation: `Bearer ${token}` },
            })
            .then(response => {
                const names = [];
                if (response.data?.businessNames?.length > 0) {
                    names.push(...response.data.businessNames);
                }
                if (response.data.entityName) {
                    names.push(response.data.entityName);
                }
                return names.sort((a, b) =>
                    a.toLowerCase().localeCompare(b.toLowerCase())
                );
            });
    };

    const saveForm = () => {
        const body = mapEntryForm(formData, saveEntryIdMapping);
        api.post(
            `/api/v1/entry/${entryId}/save`,
            { ...body, saveStep, answers },
            { headers: { JwtAuthorisation: `Bearer ${token}` } }
        ).catch(setApiError);
    };

    const submitForm = async (recaptchaRef: any) => {
        const body = mapEntryForm(formData, saveEntryIdMapping);
        return await api
            .post(
                `/api/v1/entry/${entryId}/submit`,
                { ...body, saveStep, answers },
                { headers: { JwtAuthorisation: `Bearer ${token}` } }
            )
            .then(res => {
                return res;
            })
            .catch(err => {
                setApiError(err);
                return err;
            });
    };

    const resumeForm = (entryId: string, token: string) => {
        return api
            .post(`/api/v1/entry/${entryId}/refresh-token`, { token })
            .then(tokenResponse =>
                api
                    .get(`api/v1/entry?entryId=${entryId}`, {
                        headers: {
                            JwtAuthorisation: `Bearer ${tokenResponse.data.newToken}`,
                        },
                    })
                    .then((entryResponse: any) => {
                        resetFormData();

                        dispatch(
                            sliceForm.actions.setEntry({
                                entryId: tokenResponse.data.entryId,
                                token: tokenResponse.data.newToken,
                            })
                        );

                        matchAboutYou(entryResponse.data).forEach(setFormData);
                        matchAnswers(
                            sections,
                            entryResponse.data.answers
                        ).forEach(setFormData);

                        return entryResponse.data.saveStep;
                    })
            )
            .catch(setApiError);
    };

    const resumeInviteForm = () => {
        if (Object.keys(inviterData).length > 0) {
            matchAboutYou(inviterData).forEach(setFormData);
            matchAnswers(sections, inviterData.answers).forEach(setFormData);
        }
    };

    const emailSubscribe = async (email: string) => {
        return api
            .post(
                `/api/v1/entry/subscribe`,
                { email },
                { headers: { JwtAuthorisation: `Bearer ${token}` } }
            )
            .catch(setApiError);
    };

    const isSubmittingForm = useAppSelector(
        state => state.form.isSubmittingForm
    );
    const setIsSubmittingForm = (isSubmittingForm: boolean) =>
        dispatch(sliceForm.actions.setIsSubmittingForm(isSubmittingForm));

    return {
        abnLookup,
        createEntry,
        section,
        completedSections,
        entryId,
        formData,
        setSection,
        setCompletedSections,
        setFormData,
        saveForm,
        submitForm,
        resumeForm,
        resumeInviteForm,
        apiError,
        setApiError,
        emailSubscribe,
        isSubmittingForm,
        setIsSubmittingForm,
    };
};

export default sliceForm.reducer;
