import type React from "react";
import { Helmet } from "react-helmet";
import HeroLanding from "@js/organisms/HeroLanding";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@js/atoms/Button";
import img from "@assets/img/illustration-questionnaire.svg";
import { FormIntro, ReturnMarkup } from "@js/molecules/StoryBlokComponents";
import { returnInput } from "@js/molecules/StoryBlokFormInputComponents";
import sampleFormData from "../../json/sample-form.json";

const SampleQuestionnaire: React.FC = () => {
    const navigate = useNavigate();

    const sampleFormDataJson = sampleFormData;

    const { name, intro, toolip, questions } = sampleFormDataJson;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children&apos;s Guardian | Self Assessment |
                    Invite Colleagues
                </title>
            </Helmet>
            <div className="landing">
                <HeroLanding
                    eyebrow="What to expect"
                    title="Sample Child Safe Self-Assessment – Improve your child safe practices"
                    description="Want to know a little more about the self-assessment before you begin? Read on for some sample questions and a sample report to get you started."
                />
                <div className="landing__body">
                    <div className="landing__body__inner">
                        <h2 tabIndex={0}>
                            About the Child Safe Self-Assessment
                        </h2>
                        <p tabIndex={0}>
                            The Child Safe Self-Assessment is a free online
                            resource to support organisations implement the NSW
                            Child Safe Standards.
                        </p>
                        <p tabIndex={0}>
                            After completing the self-assessment, you’ll receive
                            a personalised Action Report based on your responses
                            that provides clear actions and useful resources to
                            help your organisation improve its child safe
                            practices.
                        </p>
                        <p tabIndex={0}>
                            Your responses will not be shared with anyone and no
                            one from your organisation will know whether you
                            complete the self-assessment.
                        </p>
                        <p tabIndex={0}>
                            Multiple people from the one organisation can
                            complete the Child Safe Self-Assessment, and it can
                            be done as many times as you like. You may wish to
                            discuss your results and actions with your
                            colleagues so you can see how your organisation is
                            going as a whole.
                        </p>
                        <div className="landing__body__buttons-container">
                            <Button
                                anchorText="Start Self-Assessment"
                                outlineButton={false}
                                onClick={() => {
                                    navigate("/self-assessment");
                                }}
                            />
                            <Button
                                anchorText="Learn more"
                                outlineButton={true}
                                onClick={() => {
                                    navigate("/");
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="sample-form">
                    <div className="sample-form__inner">
                        <div className="sat__section-intro">
                            <h2 tabIndex={0} id="sat-intro-title">
                                {name}
                            </h2>
                            <FormIntro content={intro} tooltip={toolip} />
                            <img
                                src={img}
                                alt="self assessment questionnaire"
                            />
                        </div>
                        <div id="sat-form-container">
                            <div className="sat__form-container">
                                <div id="sat-form-non-standard">
                                    {questions.map(
                                        (question: FormQuestion, i: number) => {
                                            const { tooltip, _uid } = question;
                                            const answer = question.answer[0];

                                            return (
                                                <div
                                                    className={`sat__form-item `}
                                                    key={question._uid}
                                                >
                                                    <p
                                                        className="sat__form-item__question-no"
                                                        tabIndex={0}
                                                    >
                                                        Question {i + 1}
                                                    </p>
                                                    <div className="sat__form-item__markup">
                                                        <ReturnMarkup
                                                            content={
                                                                question.question
                                                            }
                                                            tooltip={tooltip}
                                                        />
                                                        {returnInput(
                                                            answer,
                                                            _uid,
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="landing__body">
                    <div className="landing__body__inner">
                        <h2 tabIndex={0}>Download a sample report</h2>
                        <p tabIndex={0}>
                            After completing the Child Safe Self-Assessment,
                            you’ll receive a personalised Assessment and Action
                            Report based on your responses. Your comprehensive
                            report will offer clear and achievable actions for
                            your organisation to improve its child safe
                            practices, alongside other valuable resources.
                        </p>
                        <p tabIndex={0}>
                            Download a sample report below. Please note, this
                            sample report is illustrative only and should not be
                            considered as the actual Child Safe Self-Assessment
                            report.
                        </p>
                        <div className="landing__body__buttons-container">
                            <a
                                href={
                                    "/assets/pdf/Self-assessment sample report.pdf"
                                }
                                className="button button__outline"
                                target="_blank"
                                download
                            >
                                Download sample report
                            </a>
                        </div>
                        <div className="landing__body__cta-container">
                            <h2 tabIndex={0}>
                                Ready to start the Child Safe Self-Assessment?
                            </h2>
                            <strong tabIndex={0}>
                                Please allow about 30 minutes to complete the
                                self-assessment. You will be able to save and
                                continue later at any point during your
                                assessment.
                            </strong>
                            <p tabIndex={0}>
                                Your responses to this self-assessment hold no
                                legal or regulatory implications for you or your
                                organisation. Thank you for making your
                                organisation safer for children.
                            </p>
                            <Button
                                className="landing__body__cta-link"
                                anchorText="Start self-assessment"
                                outlineButton={false}
                                onClick={() => {
                                    navigate("/self-assessment");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SampleQuestionnaire;
