import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@js/store/hooks";
import { api } from "@js/utilities";

interface FormState {
    inviteToken: string;
    inviterEntryId: string;
    inviterData: { [key: string]: string | Record<string, unknown> };
    inviteStatus: string;
}

const initialState: FormState = {
    inviteToken: "",
    inviterEntryId: "",
    inviterData: {},
    inviteStatus: "",
};

export const sliceInvite = createSlice({
    name: "form",
    initialState,
    reducers: {
        setInviteToken: (state, action) => {
            state.inviteToken = action.payload;
        },
        setInviteEntryId: (state, action) => {
            state.inviterEntryId = action.payload;
        },
        setInviterData: (state, action) => {
            state.inviterData = action.payload;
        },
        setInviteStatus: (state, action) => {
            state.inviteStatus = action.payload;
        },
    },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInvite = () => {
    const dispatch = useAppDispatch();

    const token = useAppSelector(state => state.form.token);
    const entryId = useAppSelector(state => state.form.entryId);
    const inviterData = useAppSelector(state => state.invite.inviterData);
    const inviteStatus = useAppSelector(state => state.invite.inviteStatus);

    const setInviteToken = (inviteToken: string, inviterEntryId: string) => {
        dispatch(sliceInvite.actions.setInviteToken(inviteToken));
        dispatch(sliceInvite.actions.setInviteEntryId(inviterEntryId));
        api.get(
            `api/v1/entry/organisation?token=${inviteToken}&e=${inviterEntryId}`
        ).then(response => {
            dispatch(sliceInvite.actions.setInviterData(response.data));
        });
    };

    const sendInvite = (inviteEmails: string[]) => {
        return api
            .post(
                `/api/v1/entry/${entryId}/invite`,
                { inviteEmails },
                { headers: { JwtAuthorisation: `Bearer ${token}` } }
            )
            .then(() => {
                dispatch(
                    sliceInvite.actions.setInviteStatus("Invitations sent!")
                );
            })
            .catch(err => {
                console.log(err.response);
                dispatch(
                    sliceInvite.actions.setInviteStatus(err.response.statusText)
                );
            });
    };

    return {
        setInviteToken,
        inviterData,
        inviteStatus,
        sendInvite,
    };
};

export default sliceInvite.reducer;
