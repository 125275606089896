import type React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeroLanding from "@js/organisms/HeroLanding";
import { ReturnMarkup } from "@js/molecules/StoryBlokComponents";
import { useStoryBlok } from "@js/slices/sliceStoryBlok";
import { Navigate, useSearchParams } from "react-router-dom";
import { useInvite } from "@js/slices/sliceInvite";
import img from "@assets/img/illustration-mailbox.svg";

const Landing: React.FC = () => {
    const [searchParams] = useSearchParams();
    const { jsonData } = useStoryBlok();
    const [ctaRedirect, setCtaRedirect] = useState<string>("");

    const { setInviteToken, inviterData } = useInvite();
    const content = jsonData?.story?.content;
    const { metatags } = content;
    const shareUrl =
        process.env.NODE_ENV === "production"
            ? "https://cssa.ocg.nsw.gov.au/"
            : "http://ocg.adrenalinmedia.com/";

    const hideClass = "landing__body__video-container-hide";

    const handleHitPlay = (e: MouseEvent | KeyboardEvent) => {
        const playButtonElement = document.getElementById("play-button");
        const coverImageElement = document.getElementById("cover-image");

        if (
            (e.type === "keydown" && (e as KeyboardEvent).key === "Enter") ||
            e.type === "click" ||
            e.type === "touch"
        ) {
            playButtonElement?.classList.add(hideClass);
            coverImageElement?.classList.add(hideClass);
            const ytVid = document.getElementById(
                "youtube-video"
            ) as HTMLMediaElement;
            ytVid.src = ytVid.src + "?autoplay=1";
            updateVideoTabIndex();
        }
    };

    const handleCtaClick = (e: MouseEvent | KeyboardEvent) => {
        const ctaLinkUrl = document
            .querySelector(".landing__body__cta-link")
            ?.getAttribute("href");

        if (
            ctaLinkUrl &&
            ((e.type === "keydown" && (e as KeyboardEvent).key === "Enter") ||
                e.type === "click")
        ) {
            e.preventDefault();
            setCtaRedirect(ctaLinkUrl);
        }
    };

    useEffect(() => {
        const playElements = document.querySelectorAll(
            "#cover-image, #play-button"
        ) as NodeListOf<HTMLElement>;
        playElements.forEach(playElement => {
            playElement?.addEventListener("click", handleHitPlay);
            playElement?.addEventListener("keydown", handleHitPlay);
        });

        const ctaLink = document.querySelector(
            ".landing__body__cta-link"
        ) as HTMLAnchorElement;
        ctaLink?.addEventListener("click", handleCtaClick);
        ctaLink?.addEventListener("keydown", handleCtaClick);

        return () => {
            playElements.forEach(playElement => {
                playElement?.addEventListener("click", handleHitPlay);
                playElement?.addEventListener("keydown", handleHitPlay);
            });
            ctaLink?.removeEventListener("click", handleCtaClick);
            ctaLink?.removeEventListener("keydown", handleCtaClick);
        };
    }, []);

    const inviteToken = searchParams.get("inviteToken");
    const inviterEntryId = searchParams.get("e");

    useEffect(() => {
        if (inviteToken && inviterEntryId) {
            setInviteToken(inviteToken, inviterEntryId);
        }
    }, [inviteToken, inviterEntryId]);

    useEffect(() => {
        const contentElements = document.getElementById(
            "story-markup-content"
        )?.children;

        if (contentElements) {
            for (let i = 0; i < contentElements.length; i++) {
                const nodeName = contentElements[i].nodeName;
                if (
                    nodeName === "H2" ||
                    nodeName === "H3" ||
                    nodeName === "P"
                ) {
                    contentElements[i].setAttribute("tabindex", "0");
                }
            }
        }
        updateVideoTabIndex();
    }, [content]);

    const updateVideoTabIndex = () => {
        const tabIndex = document
            .getElementById("play-button")
            ?.classList.contains("landing__body__video-container-hide")
            ? "0"
            : "-1";
        const iframe = document.getElementById("youtube-video");
        if (iframe) {
            iframe.setAttribute("tabindex", tabIndex);
        }
    };

    return (
        <>
            {ctaRedirect && <Navigate to={ctaRedirect} />}
            <Helmet>
                <meta charSet="utf-8" />
                <meta property="description" content={metatags.description} />
                <meta property="og:title" content={metatags.og_title} />
                <meta
                    property="og:description"
                    content={metatags.og_description}
                />
                <meta property="og:image" content={metatags.og_image} />
                <meta property="og:url" content={shareUrl} />
                <meta name="twitter:title" content={metatags.twitter_title} />
                <meta
                    name="twitter:description"
                    content={metatags.twitter_description}
                />
                <meta name="twitter:image" content={metatags.twitter_image} />
                <title>{metatags.title}</title>
            </Helmet>
            <div className="landing">
                <HeroLanding />
                <div className="landing__body">
                    <div className="landing__body__inner">
                        {typeof inviterData.inviterName === "string" ? (
                            <div className="landing__body__inner-invite">
                                <img src={img} />
                                <div className="landing__body__inner-invite-content">
                                    <h2>
                                        Why have I been invited to complete this
                                        Child Safe Self-Assessment?
                                    </h2>
                                    <p>
                                        You have been nominated by{" "}
                                        {inviterData.inviterName} to complete a
                                        Child Safe Self-Assessment for your part
                                        of the organisation. The organisation
                                        questions have been answered for you by
                                        this person to save you time. You have
                                        the option to edit any of these
                                        responses for your submission if you
                                        wish.
                                    </p>
                                    <p>
                                        You will receive your own Assessment and
                                        Action Report based on your responses.
                                        Your responses will not be shared with
                                        anyone, nor will anyone from your
                                        organisation know whether you complete
                                        this self-assessment. You may wish to
                                        discuss your results and actions with
                                        your collegues so you can see how your
                                        organisation is going as a whole.
                                    </p>
                                </div>
                            </div>
                        ) : null}
                        <ReturnMarkup
                            content={content.body[0].content_detail}
                            tooltip={[]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Landing;
