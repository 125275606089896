import type React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { api } from "@js/utilities";
import HeroErrorBoundary from "@js/organisms/HeroErrorBoundary";
import { Button } from "@js/atoms/Button";

const heroMessage = "Unsubscribe from emails for this self-assessment.";

const getParameterByName = (name: string, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const Unsubscribe: React.FC = () => {
    const [message, setMessage] = useState<string>("");

    const handleUnsubscribe = () => {
        const eid = getParameterByName("eid");
        const e = getParameterByName("e");

        api.post(`/api/v1/entry/${eid}/unsubscribe?e=${e}`)
            .then(() => setMessage("You have been unsubscribed"))
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    Office of the Children's Guardian | Self Assessment |
                    Unsubscribe
                </title>
            </Helmet>
            <div className="landing">
                <HeroErrorBoundary message={heroMessage} />
                <div className="landing__body-error">
                    <div className="landing__body__inner">
                        <p className="mt-3">
                            Are you sure you want to unsubscribe? You will no
                            longer receive reminder emails for this
                            self-assessment.
                        </p>
                        <div className="mb-5 mt-1">
                            <Button
                                anchorText="Unsubscribe"
                                outlineButton={false}
                                onClick={() => handleUnsubscribe()}
                                disabled={message !== "" ? true : false}
                            />
                            {message !== "" && (
                                <div className="sat__form-submit-message">
                                    <i className="icon icon-check invite-status" />
                                    <p className="m-0">{message}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Unsubscribe;
