import type React from "react";
import { useEffect } from "react";
import { useModal } from "@js/slices/sliceModal";
import { TextInput } from "@js/molecules/StoryBlokFormInputComponents";
import { Button } from "@js/atoms/Button";
import { useForm } from "@js/slices/sliceForm";

interface ModalProps {
    show: boolean;
}

const Modal: React.FC<ModalProps> = () => {
    const { showModal, type, setShowModal } = useModal();
    const { saveForm } = useForm();

    useEffect(() => {
        const element = document.getElementById("modal-close");
        element?.focus();
    }, [showModal]);

    const handleKeyDownClose = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            setShowModal("");
        }
    };

    return (
        <>
            <div
                onClick={() => {
                    setShowModal(type === "save" ? "save" : "continue");
                }}
                className={`modal ${showModal ? "modal__show" : "modal__hide"}`}
            >
                <div
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    tabIndex={0}
                    className={`modal__body ${
                        showModal ? "modal__body__show" : "modal__body__hide"
                    }`}
                >
                    <div className="modal__body-inner">
                        <a
                            className="modal__body-close"
                            id="modal-close"
                            tabIndex={0}
                            onClick={e => {
                                e.preventDefault();
                                setShowModal(
                                    type === "save" ? "save" : "continue"
                                );
                            }}
                            onKeyDown={e => handleKeyDownClose(e)}
                        >
                            <i className="icon icon-close" />
                        </a>
                        {type === "save" && (
                            <>
                                <h1>Save and continue later</h1>
                                <p>
                                    You can save your progress at any time and
                                    continue later. We will send you an email
                                    with a link to return to this form.
                                </p>
                                <strong>
                                    Your form will be saved for 30 days.
                                </strong>
                                <TextInput
                                    label="Send my progress email to"
                                    placeholder="email@example.com"
                                    required={false}
                                    disabled={false}
                                    type="email"
                                    questionId="email-q"
                                    answerId="email-a"
                                />
                                <Button
                                    onClick={e => {
                                        e.preventDefault();
                                        saveForm();
                                        setShowModal("save");
                                    }}
                                    anchorText="Send now"
                                    outlineButton={false}
                                    className="modal__body-save"
                                />
                                <div>
                                    <a
                                        style={{ cursor: "pointer" }}
                                        tabIndex={0}
                                        className="modal__body-cancel"
                                        onClick={e => {
                                            e.preventDefault();
                                            setShowModal("save");
                                        }}
                                        onKeyDown={e => handleKeyDownClose(e)}
                                    >
                                        Cancel and do not save
                                    </a>
                                </div>
                            </>
                        )}
                        {type === "continue" && (
                            <>
                                <h1>Sorry, that link has expired</h1>
                                <p>
                                    It looks like the questionnaire you&apos;re
                                    trying to access is no longer available. We
                                    only store answers for 30 days.
                                </p>
                                <Button
                                    onClick={e => {
                                        e.preventDefault();
                                        setShowModal("continue");
                                    }}
                                    anchorText="OK"
                                    outlineButton={false}
                                    className="modal__body-save"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
