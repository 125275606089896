import type React from "react";
import { useEffect, memo } from "react";
import Loader from "@js/atoms/Loader";

import { useStoryBlok } from "@js/slices/sliceStoryBlok";
import { LoaderForm, LoaderHome, LoaderFaq } from "@js/molecules/LoaderPages";
import Thankyou from "@js/containers/Thankyou";

const StoryblokFetcher: React.FC<{
    path: string;
    children: React.ReactElement | null;
}> = memo(function StoryblokFetcher({ path, children }) {
    const { fetchContent, jsonData, storyblokPath } = useStoryBlok();

    useEffect(() => {
        fetchContent(path);
    }, [path]);

    return storyblokPath === path && jsonData ? (
        children
    ) : (
        <Loading path={storyblokPath} />
    );
});

export default StoryblokFetcher;

const Loading: React.FC<{ path: string }> = ({ path }) => {
    const returnLoader = () => {
        switch (path) {
            case "sat-form":
                return <LoaderForm />;
            case "home":
                return <LoaderHome />;
            case "help":
                return <LoaderFaq />;
            case "thank-you":
                return <Thankyou />;
            default:
                <div className="faq">
                    <div className="faq__inner">
                        <div className="mt-3" />
                        <Loader />
                        <Loader loaderSize="large" repeat={5} />
                    </div>
                </div>;
        }
    };

    return <>{returnLoader()}</>;
};
