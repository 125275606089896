import type React from "react";
import img from "@assets/img/illustration-invite.svg";

const HeroInvite: React.FC = () => {
    return (
        <>
            <div className="invite__hero">
                <div className="invite__hero__inner">
                    <div tabIndex={0} className="invite__hero__text-container">
                        <h1>
                            Invite colleagues to complete their own
                            self-assessment
                        </h1>
                        <p>
                            Invite a colleague and share your responses to the
                            organisation questions to help them save time
                            completing their own self assessment!
                        </p>
                        <p>
                            Larger organisations that have different business
                            units, services or operating environments may
                            benefit from doing a self-assessment for each unit
                            or service.
                        </p>
                    </div>
                    <img src={img} alt="Child safe self assessment complete" />
                </div>
            </div>
        </>
    );
};

export default HeroInvite;
