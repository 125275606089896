import type React from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "@js/slices/sliceForm";
import { useStoryBlok } from "@js/slices/sliceStoryBlok";
import { LoaderForm } from "@js/molecules/LoaderPages";

const Continue: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { resumeForm, setSection, setCompletedSections } = useForm();
    const { jsonData } = useStoryBlok();

    const entryId = searchParams.get("eid");
    const saveToken = searchParams.get("t");

    useEffect(() => {
        if (!entryId || !saveToken) {
            navigate(`/`);
        } else {
            resumeForm(entryId, saveToken).then(saveStep => {
                const section = jsonData?.story?.content.sections.findIndex(
                    (s: { pageSlug: string }) => s.pageSlug === saveStep
                );
                setSection(section);
                setCompletedSections(section);
                navigate(`/self-assessment/${section}`, { replace: true });
            });
        }
    }, [
        entryId,
        saveToken,
        resumeForm,
        jsonData,
        setSection,
        setCompletedSections,
        navigate,
    ]);

    return <LoaderForm />;
};

export default Continue;
