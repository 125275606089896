export const createEntryIdMapping = {
    firstName: "first-name-q",
    lastName: "last-name-q",
    email: "email-q",
    phoneNumber: "phone-q",
    role: "role-q",
    consentAuthorised: "consent-q",
};

export const saveEntryIdMapping = {
    ...createEntryIdMapping,
    abn: "abn-q",
    entityName: "business-name-q",
    addressLine1: "street-address-q",
    addressUnit: "street-address-suite-apt-q",
    addressSuburb: "suburb-q",
    addressState: "state-q",
    addressPostcode: "postcode-q",
    locationOrBusinessUnit: "location-or-business-unit-q",
};

export const roleIdMapping = {
    "employee-or-volunteer": "employee-or-volunteer-1",
    "manager-or-supervisor-or-team-leader":
        "manager-or-supervisor-or-team-leader-2",
    "human-resources-team": "human-resources-team-3",
    "executive-or-board-member": "executive-or-board-member-4",
};

type AboutYouType = keyof typeof saveEntryIdMapping;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const matchAboutYou = (responses: {
    [key in AboutYouType]: string | boolean;
}) => {
    return (Object.keys(saveEntryIdMapping) as AboutYouType[])
        .map(key => {
            const answer = responses[key] === true ? "true" : responses[key];
            const mappedKey = saveEntryIdMapping[key];
            const type =
                (
                    {
                        email: "email",
                        consentAuthorised: "checkbox",
                        role: "radio",
                    } as any
                )[key] || "text";
            if (answer) {
                return {
                    questionId: mappedKey,
                    value: answer,
                    type,
                    answerId:
                        (
                            {
                                role: (roleIdMapping as any)[answer as string],
                                consentAuthorised: "consent-1",
                            } as any
                        )[key] || mappedKey.replace("-q", "-a"),
                };
            }
        })
        .filter(v => v) as any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const matchAnswers = (sections: any[], answers: any[]) => {
    const matchedAnswers: any[] = [];
    answers.forEach((savedAnswer: any) => {
        const answer = sections
            .map((section: any) => section.questions)
            .flat()
            .find((question: any) => question._uid === savedAnswer.questionId)
            .answer[0];

        if (!savedAnswer.answer && savedAnswer.answerAdditional) {
            savedAnswer.answer = "other";
        } else if (!savedAnswer.answer) {
            return;
        }

        if (answer.type === "radio" || answer.type === "dropdown") {
            const selected = answer.options.find(
                (option: any) =>
                    option.value === savedAnswer.answer ||
                    (!option.value && savedAnswer.answer === "other")
            );
            if (selected) {
                matchedAnswers.push({
                    questionId: savedAnswer.questionId || "",
                    value: selected.value,
                    type:
                        answer.type === "dropdown" ? "select-one" : answer.type,
                    answerId: selected._uid,
                });
                if (selected.value === "" || selected.value === "other") {
                    matchedAnswers.push({
                        questionId: savedAnswer.questionId || "",
                        value: savedAnswer.answerAdditional || "",
                        type: "textarea",
                        answerId: selected._uid,
                    });
                }
            }
        } else if (
            answer.type === "slider" ||
            answer.type === "text" ||
            answer.type === "email" ||
            answer.type === "tel" ||
            answer.type === "textarea"
        ) {
            matchedAnswers.push({
                questionId: savedAnswer.questionId || "",
                value: savedAnswer.answer || "",
                type: answer.type,
                answerId: answer._uid,
            });
        } else if (answer.type === "checkbox") {
            savedAnswer.answer.split("|").forEach((multiAnswer: any) => {
                const selected = answer.options.find(
                    (option: any) => option.value === multiAnswer
                );
                if (selected) {
                    matchedAnswers.push({
                        questionId: savedAnswer.questionId || "",
                        value: selected.value,
                        type: answer.type,
                        answerId: selected._uid,
                    });
                    if (selected.value === "other") {
                        matchedAnswers.push({
                            questionId: savedAnswer.questionId || "",
                            value: savedAnswer.answerAdditional || "",
                            type: "textarea",
                            answerId: selected._uid,
                        });
                    }
                }
            });
        }
    });
    return matchedAnswers;
};
