import type React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "@js/slices/sliceForm";
import { useStoryBlok } from "@js/slices/sliceStoryBlok";

const HeaderProgressBar: React.FC = () => {
    const [progress, setProgress] = useState<number>(0);
    const [step, setStep] = useState<number>(1);
    const [isForm, setIsForm] = useState(false);
    const location = useLocation();
    const { section } = useForm();
    const { jsonData } = useStoryBlok();
    const totalSections = jsonData?.story?.content?.sections?.length;

    useEffect(() => {
        const { pathname } = location;

        if (pathname.toLowerCase().indexOf("self-assessment") > -1) {
            setIsForm(true);
            // for form the bar should never be at 100% (only when submitted)
            setProgress(((section + 1) / totalSections) * 95);
        } else {
            setIsForm(false);
        }

        setStep(section + 1);
    }, [location.pathname, section, totalSections]);

    return (
        <>
            <div className="header-progress-bar">
                <span>
                    {isForm
                        ? `Step ${step} of ${
                              totalSections ? totalSections : 14
                          }`
                        : "Questionnaire complete!"}
                </span>
                <div className="header-progress-bar__indicator">
                    <div
                        style={
                            isForm
                                ? { width: `${progress}%` }
                                : { width: "100%" }
                        }
                        className="header-progress-bar__indicator-fill"
                    ></div>
                </div>
            </div>
        </>
    );
};

export default HeaderProgressBar;
